avatar {
    display: inline-block;
}

.avatar {
    color: var(--foreground-color-0);
    border-radius: 100%;
    position: relative;
    font-size: 24px;

    &.square {
        border-radius: 0;

        .outer-wrapper, img {
            border-radius: 0;
        }
    }

    &.small {
        font-size: 14px;

        .outer-wrapper {
            height: 32px;
            width: 32px;
        }
    }

    &.medium {
        font-size: 20px;

        .outer-wrapper {
            height: 42px;
            width: 42px;
        }
    }

    &.medium.large {
        font-size: 32px;

        .outer-wrapper {
            height: 60px;
            width: 60px;
        }
    }

    &.large {
        font-size: 36px;

        .outer-wrapper {
            height: 80px;
            width: 80px;
        }
    }

    &.important {
        .outer-wrapper {
            border: 2px solid;
        }

        &.large {
            .outer-wrapper {
                border-width: 4px;
            }
        }
    }

    &.zoomable {
        cursor: pointer;
        transition:
            transform .3s cubic-bezier(0.175, 0.885, 0.320, 1.275),
            box-shadow .2s ease, border-width .1s ease;
    }

    &.zoomed {
        transform: scale(3) translateY(10px);
        box-shadow: rgba(0,0,0,0.4) 0 2px 50px;
    }

    .outer-wrapper {
        border-radius: 100%;
        box-sizing: content-box;
        display: inline-flex;
        vertical-align: middle;
        height: 60px;
        width: 60px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        &.colored-background {
            background-color: var(--contrast-color);
        }

        &.colored-background.outlined {
            background-color: transparent;
            border: 1px solid var(--contrast-color);
            color: var(--contrast-color);
        }
    }

    .image-wrapper {
        height: 100%;
        width: 100%;
    }

    img {
        border-radius: 100%;
        object-fit: cover;
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: opacity .2s linear;

        &.asset-loaded {
            opacity: 1;
        }
    }
}
