#pip-wrapper {
    margin-left: -15px;
    margin-right: -15px;

    pip {
        position: relative;
        &.maximized {
            position: fixed;
        }
    }

    &.detached {
        margin: 0;

        pip, video-call {
            position: fixed;
        }
    }

    &.fullscreen {
        pip {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1016;
            margin: 0;
        }
    }

    update-browser-box {
        .content{
            border-width: thin;
            border-style: solid;
            margin-bottom: 16px;
        }
    }

    &.hidden {
        height: 0;
        padding: 0;
    }
}

pip {
    display: block;
    height: 500px;
    background: #000;
    border-radius: 4px;
    margin-bottom: 15px;
    overflow: hidden;

    &.home-feed-width {
        max-width: $main-content-central-column-narrowmode;
    }

    &::after {
        display: none;
        content: '';
        height: 0;
        // on a large screen, the below 2 lines results in a 900px x 506.25px pip
        width: 100%;
        padding-bottom: percentage(math.div(9, 16));
    }

    &.with-stream {
        height: unset;

        &::after {
            display: block;
        }

        &.webinar-pip, &.webinar-pip.is-small-screen {
            height: 100vh;
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }

        &.webinar-pip.is-small-screen.has-side-bar{
            min-height: 800px;
        }

        &.is-small-screen.has-side-bar {
            height: 800px;

            &::after {
                display: none;
            }
        }

        .iframe-container {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    &.pinned, &.maximized {
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 1010;
        margin-bottom: 0;
    }

    &.dragging.pinned {
        .iframe-container {
            visibility: hidden;
        }
    }

    &:hover,
    &.dragging {
        box-shadow: none;

        &.maximized {
            box-shadow: none;
        }
    }

    &.maximized {
        bottom: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        left: 0 !important;
        border-radius: 0;
    }

    .iframe-container {
        overflow: hidden;
        height: 100%;
        min-height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000000;

        video-player {
            width: 100%;
            height: 100%;
        }

        // backward compatibility, see https://github.com/Spotme/spotme-webapp/pull/2440#issuecomment-1255177147
        new-video-player {
            width: 100%;
            height: 100%;
        }
    }
}

.theatre-mode {
    overflow: hidden;

    // on safari ios 100vh allows to scroll enough to hide the url bar
    height: 100vh;

    // on safari ios if you scroll fast you can see some content below the fullscreen element, moving it down:
    background-color: black;
    padding-top: 100vh;
}

@media (max-width: $screen-xs-max) {
    pip {
        &.maximized {
            position: initial;
        }
    }

    #pip-wrapper.detached {
        padding-top: 0;
    }

    #pip-wrapper {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: $screen-sm-max) {
    pip {
        border-radius: 0;

        &.maximized {
            position: initial;
            padding: 15px;
        }

        &.maximized {
            display: flex;
            flex-direction: column;
        }
    }

    pip.with-stream {
        &::after {
            display: none;
        }

        .iframe-container {
            position: static;
        }
    }
}

// override for chats, title fix
.current_page_nav_conversation #pip-wrapper.video-call {
    margin-bottom: 10px;
}
