update-browser {
    .close-btn {
        position: absolute;
        right: 18px;
        top: 18px;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
    }
    
    .browser-banner {
        display: none;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        padding: 8px;
        z-index: 10000;
        background-color: #B30A00;
        color: white;
        text-align: center;
        height: 40px;
        line-height: 24px;
        cursor: pointer;

        p {
            margin: 0;
        }
    }
}


body.update-browser-banner {
    padding: 100px 0; 

    .browser-banner {
        display: block;
    }

    app-nav-bar {
        top: 40px;
    }
}

update-browser-box {
    .content{
        display: block;

        text-align: center;
        padding: 24px 28px 32px 28px;
        border-radius: 4px;

        .title {
            font-size: 20px;
            line-height: 32px;
        }
    }

    .body {
        .text {
            padding: 14px 12px 32px 12px;
        }
        .browsers {
            padding: 0;
            margin: 0;

            display: flex;
            justify-content: space-around;
        }
        .browser {
            list-style: none;
            border-radius: 4px;
            cursor: pointer;

            width: 120px;
            height: 152px;

            .browser-img {
                width: 64px;
                height: 64px;
                margin: 24px 28px 8px 28px;
                background-repeat: no-repeat;
                background-size: contain;

                &.chrome {
                    background-image: image-url('chrome.png');
                }
                &.edge {
                    background-image: image-url('edge.png');
                }
                &.firefox {
                    background-image: image-url('firefox.png');
                }
                &.opera {
                    background-image: image-url('opera.png');
                }
            }

            .name {
                font-weight: bold;
                line-height: 20px;
                margin: 0;
                text-transform: capitalize;
            }
        }
    }
}