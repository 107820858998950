.suggestions {
  position: relative;

  %popover {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
    left: 0;
    z-index: 1;
    margin-top: 8px;
    border: 1px solid #f3f3f3;
    border-radius: 8px;
  }

  .documents {
    @extend %popover;
    right: 0;

    .doc,
    .create-new {
      padding: 5px 20px;
      cursor: pointer;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }

  .doc,
  .create-new {
    @include clearfix;
    padding: 5px 0;
    line-height: 28px;

    i {
      float: right;
      cursor: pointer;
      margin-top: 7px;
      color: #9b9b9b;
    }
  }

  .sub {
    margin-left: 12px;
    color: var(--foreground-color-2);
    font-size: 12px;
  }

  .create-new {
    color: var(--contrast-color);
  }
}
