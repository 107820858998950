#pip-wrapper {
    pip-controls {
        display: none;
    }

    &.detached pip-controls {
        display: block;

        &:hover {
            .pip-controls {
                .area-controls {
                    opacity: 1;
                }
            }
        }

        .pip-controls {
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 8px;
            z-index: 15;
            overflow: hidden;
            cursor: move;
            display: flex;
            align-items: center;
            justify-content: center;

            .area-controls {
                border-radius: 8px;
                cursor: pointer;
                opacity: 0;

                .reattach-btn {
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    background-color: rgba(0, 0, 0, .5);
                    border-radius: 4px;
                    font-size: 24px;
                    padding: 16px;

                    &:hover {
                        background-color: rgba(255, 255, 255, .2);
                    }
                }
            }

            .close-btn {
                position: absolute;
                top: 8px;
                right: 8px;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                background-color: #252627;
                border-radius: 4px;
                border: 1px solid #484D51;
                cursor: pointer;

                &:hover {
                    background-color: #484D51;
                }
            }
        }
    }
}
