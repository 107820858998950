.nav-spotman .row-profile {
    overflow: hidden;
    transition: opacity 0.3s ease, transform 0.5s ease;

    &.search-focused {
        opacity: 0;
        transform: translateY(-50px);
    }

    .media-body {
        vertical-align: middle;
    }

    .nav-list-item-title {
        line-height: 1.2rem;
    }

    .row-profile-photo {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 90%;
        border-radius: 50%;
        background: $spotme-light-grey;
        color: #fff;
        text-align: center;
        text-transform: uppercase;

        img {
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        span {
            text-transform: uppercase;
        }

        &.child-asset-loaded {
            img {
                opacity: 1;
            }
            span {
                display: none;
            }
        }
    }
}
