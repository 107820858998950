#unsupported {
    margin: 100px auto;
    text-align: center;

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
        color: #989898;
    }
}
