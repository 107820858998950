chat-details {
    display: block;
    overflow: hidden;
}

chat-details, .chat-details, .invites {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    padding-top: $nav-header-height;

    &.in {
        transform: translate3d(0, 0, 0);
    }

    .nav-content {
        bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
    }
}

.chat-details {
    padding: 30px 16px;

    h3 {
        font-size: 16px;
        font-weight: normal;
        border-bottom: 1px solid #dee0e1;
        margin: 0 0 16px;
        line-height: 28px;

        .count {
            float: right;
            display: inline-block;
        }
    }

    .pax {
        margin-bottom: 16px;

        .more {
            float: right;
        }
    }

    .media {
        display: flex;
        align-items: center;
    }

    .media-left, .media-body {
        display: block;
        vertical-align: middle;
        flex: 1 0 auto;
    }

    .media-left {
        max-width: 64px;
    }

    .action {
        border-bottom: 1px solid #dee0e1;

        &.highlight {
            a {
                color: #ff3b30;
            }
        }

        a {
            display: block;
            padding: 14px 0;
            font-size: 16px;
            color: #4a4a4a;
            cursor: pointer;

            &.hover {
                text-decoration: none;
            }
        }

        i {
            min-width: 24px;
            display: inline-block;
        }
    }

    .btn {
        background-color: transparent;
    }
}

.invites {
    z-index: 3;

    .nav-header {
        padding: 0 16px;
    }

    form {
        padding: 30px 16px;
    }
}
