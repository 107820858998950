.toggler {
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;

    &.disabled {
        cursor: not-allowed !important;

        > span {
            background-color: #E3E6E8;
        }
    }

    > span {
        margin: 10px 8px;
        display: inline-block;
        width: 32px;
        height: 16px;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        background-color: #E3E6E8;
        vertical-align: middle;
    }

    > span.switch-on {
        background-color: #35C759;
    }

    span.switch {
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 8px;
        background-color: white;
        position: absolute;
        top: 2px;
        left: 2px;
        transition: left .1s linear, background-color .1s linear;
    }

    input {
        position: absolute;
        left: - 14px;
    }

    input:checked + span.switch {
        left: calc(100% - #{14px});
    }

    .label {
        .no-subtitle {
            margin-top: 8px;
        }
        .subtitle {
            font-size: 12px;
            color: #7D8082;
        }
    }
}
