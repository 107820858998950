nav-header-actions,
.nav-header-actions {
    .btn {
        i[class^='icon'] {
            font-size: 20px;
            vertical-align: middle;
        }

        span {
            display: none;
        }

        i[icon] {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &[action-style='text'] {
        .btn img {
            display: none;
        }
        .btn span {
            display: inline-block;
        }
    }

    &:empty {
        display: none;
    }
}

nav nav-header-actions,
nav .nav-header-actions {
    float: right;

    .btn {
        padding: 0 10px;

        &:hover,
        &.disabled,
        &[disabled] {
            background: none !important;
        }
    }
}

sheet nav-header-actions,
sheet .nav-header-actions {
    margin-right: 5px;

    .btn {
        display: flex;
        align-items: center;
    }

    .btn i[icon] {
        margin-right: 3px
    }
}

@media screen and (max-width: 767px) {
    nav-header-actions + .nav-header-actions {
        .action-button {
            position: static;
        }
    }
}
