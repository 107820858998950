@import 'frontloader-common/vendor/animate.css/fading_entrances/fadeIn';
@import 'frontloader-common/vendor/animate.css/fading_entrances/fadeInDown';

@import 'frontloader-common/vendor/animate.css/fading_exits/fadeOut';
@import 'frontloader-common/vendor/animate.css/fading_exits/fadeOutDown';

@import 'frontloader-common/vendor/animate.css/sliding_entrances/slideInLeft';
@import 'frontloader-common/vendor/animate.css/sliding_entrances/slideInRight';

@import 'frontloader-common/vendor/animate.css/sliding_exits/slideOutLeft';
@import 'frontloader-common/vendor/animate.css/sliding_exits/slideOutRight';

@import 'frontloader-common/vendor/animate.css/attention_seekers/shake';
@import 'frontloader-common/vendor/animate.css/attention_seekers/pulse';
@import 'frontloader-common/vendor/animate.css/attention_seekers/flash';

@keyframes slideInFromRight {
    from { transform: translateX(150%); }
    to { transform: translateX(0%); }
}

@keyframes slideOutToRight {
    from { transform: translateX(0%); }
    to { transform: translateX(150%); }
}


@keyframes zoomInTopRight {
    0% {
        opacity: 0;
        transform: scale3d(0.2, 0.2, 0.2);
        transform-origin: top left;
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        transform-origin: top left;
    }
}

@keyframes zoomOutTopRight {
    0% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        transform-origin: top left;
    }

    100% {
        opacity: 0;
        transform: scale3d(0.2, 0.2, 0.2);
        transform-origin: top left;
    }
}

.zoomInTopRight {
    animation-name: zoomInTopRight;
}

.zoomOutTopRight {
    animation-name: zoomOutTopRight;
}

@keyframes growAndFadeUp {
    0% {
        transform: scale(0) translateY(0);
        opacity: 0;
    }

    10% {
        transform: scale(1.10) translateY(0);
        opacity: 1;
    }

    15% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(.8) translateY(-200px);
        opacity: 0;
    }
}
