.nav-media {
    background: #3c3c3c;
    text-align: center;
    height: 100%;
    padding: 10px;
    overflow: hidden;

    video {
        max-width: 100%;
    }

    a {
        color: white;
        display: inline-block;
        margin-top: 20px;
    }

    audio {
        margin-top: 20px;
    }

    i {
        margin-right: 10px;
    }
}
