/**
 * Given an image name this mixin returns a path
 * for the relative image.
 *
 * @param $image the name of the image to link.
 */
@function image-path($image) {
  @return "#{$image-path}/#{$image}";
}


/**
 * Given a font name this mixin returns a path
 * for the relative font.
 *
 * @param $font the name of the font to link.
 */
@function font-path($font) {
  @return "#{$fonts-path}/#{$font}";
}

/**
 * Given an image name this mixin returns an url clause
 * for the relative image.
 *
 * @param $image the name of the image to link.
 */
@function image-url($image) {
  @return url(image-path($image));
}

/**
 * Given a font name this mixin returns an url clause
 * for the relative font.
 *
 * @param $font the name of the font to link.
 */
@function font-url($font) {
  @return url(font-path($font));
}

/**
 * This is a pure SASS replacement for the ruby "font-files" helper
 * implemented by compass.
 * Use this helper to concatenate multiple font file format.
 */
@function font-files($fonts...) {
  $font-files: null;

  @each $font in $fonts {
    $file: nth($font, 1);
    @if (length($font) == 2) {
      $format: nth($font, 2);
      $font-files: $font-files, font-url($file) unquote("format('#{$format}')");
    } @else {
      $font-files: $font-files, font-url($file);
    }
  }

  @return $font-files;
}

@function calculate-rem($size) {
  $rem-size: math.div($size, $font-size-base);
  @return $rem-size + rem;
}
