$block_margin: 6.5px;
$block_single_height: 100px;
$block_double_height: ($block_single_height + $block_margin)*2;

.nav-blocks {
    background: transparent !important;
    border: none !important;

    .slider-container {
        padding: 0;

        li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .rn-carousel-indicator {
        position: absolute;
        right: $block_margin*2;
        bottom: $block_margin*2;

        margin-bottom: 3px;
        border-radius: 10px;

        z-index: 1;

        span {
            cursor: pointer;
            color: transparent;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 50%;
            display: inline-block;
            width: 10px;
            height: 10px;
            font-size: 0.001em;
            margin: 0 5px 0;
            background: #fff;

            &.active {
                color: rgba(0, 0, 0, 0);
                background: rgba(0, 0, 0, 0.2);
                border-color: rgba(0, 0, 0, 0.4);
            }
        }
    }

    .legal-disclaimer {
        text-align: center;
        font-size: 0.8em;
        margin-top: 20px;
        animation-duration: .3s;
        opacity: 0.7;
        margin-bottom: 20px;
    }

    .blocks-root {
        box-sizing: border-box;
        // padding: $block_margin;
        margin: 0 auto;

        .block-group {
            margin: 0;
            width: 50%; // width-half
            float: left;
            box-sizing: border-box;
            animation-duration: 0.5s;

            &:before,
            &:after {
                content: '';
                display: table;
            }
            &:after {
                clear: both;
            }

            &-width-full {
                width: 100%;
            }
        }

        .block .panel.panel-default {
            margin: 0 0 $block_margin*2 0;
        }
    }

    .block {
        .panel-heading {
            .title {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        .panel-active {
            cursor: pointer;
        }
    }

    .block.block_banner {
        margin-bottom: $block_margin*2;

        .slider-container {
            height: $block_single_height;
            margin: 0;
            border-radius: 4px;
        }

        .layer {
            width: 100%;
            height: 100%;
            position: relative;

            .image {
                border-radius: 4px;
                height: 100%;
            }

            .title {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 5px;
                line-height: 100%;

                &.theme-vertical-align-top {
                    top: 0;
                }
            }
        }

        .layer .image {
            width: 100%;

            &:after {
                padding-bottom: 25%;
                content: '';
                height: 1px;
                width: 1px;
                margin-left: -1px;
                margin-top: -1px;
                display: block;
            }
        }
    }

    .block.block_slider {

        slick {
            position: relative;

            .panel.panel-default .panel-body {
                padding-bottom: $slick-dot-size;
            }
        }

        .slider-container {
            height: $block_single_height + $block_margin*2;
            margin: 0;
        }
        .panel.panel-default {
            height: $block_single_height;
            overflow: hidden;
        }

        &.block-height-double {
            .slider-container {
                // I can't explain how this computes
                height: 226px;
            }
            .panel.panel-default {
                height: $block_double_height;
            }
        }
    }

    .block-group-width-full .block.block_text {
        height: $block_single_height + $block_margin*2;
    }

    .block.block_text {
        .panel.panel-default {
            transition: opacity 0.3s ease;
            height: $block_single_height;

            &.ng-click-active {
                opacity: 0.8;
            }

            .panel-heading {
                border-bottom: none;
                padding-bottom: 0;

                &:only-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    padding-bottom: 10px;
                }
            }

            .panel-body {
                height: 100%;
                overflow: auto;
            }

            .media {
                width: 100%;
                display: table;
            }

            .media-body {
                width: 100%;
            }

            .media-object {
                margin-top: -5px;
                opacity: 0;
                transition: opacity 0.3s ease;
            }

            &.with-title {
                .media-object {
                    margin-top: -15px;
                    max-height: 64px;
                    max-width: 64px;
                }

                .media,
                .media-body {
                    height: 100%;
                }

                .media-left,
                .media-right,
                .media-body {
                    vertical-align: middle;
                }

                .media-object {
                    margin: 0;
                }

                .panel-heading {
                    padding: 0;
                }
            }

            .media-object.asset-loaded {
                opacity: 1;
            }
        }

        &.block-height-double {
            height: $block_double_height + $block_margin*2;

            .panel.panel-default {
                height: $block_double_height;
            }
        }
    }

    .block.block_image {
        position: relative;

        .panel-heading {
            position: absolute;
            bottom: 0;
            border-bottom: 0;
            width: calc(100% - #{$block_margin*2});
            border-radius: 0;
            padding: 10px 15px;
            color: #fff;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            &.theme-vertical-align-top {
                bottom: auto;
                top: 0;
                border-radius: 0;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &.theme-vertical-align-center {
                top: 0;
                margin: auto;
                height: 1em;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .image {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50%;
            background-color: transparent;
            opacity: 0;
            transition: opacity 0.3s ease;

            &.asset-loaded {
                opacity: 1;
            }
        }

        &.block-height-single .panel {
            height: $block_single_height;
        }

        &.block-height-double .panel {
            height: $block_double_height;
        }
    }

    .block.block_list {
        .list-group-item {
            // clears a couple of blank pixels at the bottom of the list
            // when you have three items and the last one has a side tag
            padding-top: 11px;
            padding-bottom: 11px;
            padding-left: 10px;
            cursor: pointer;

            // for one item lists, the item will be stretched to
            // fill the whole list (when there's a background colour)
            &.first.last {
                height: $block_double_height;
                border-bottom: none;
            }

            &.disabled {
                cursor: default;
            }
        }
    }

    .upload-preview:empty {
        border: 0;
    }
}

@media (max-width: $screen-xs-max) {
    .nav-blocks {
        .blocks-root {
            padding: 0;

            .block-group {
                width: 100%;
                float: none;
            }
        }

        .block .panel.panel-default:first-child {
            margin-left: $block_margin*2;
        }
        .block .panel.panel-default:last-child {
            margin-right: $block_margin*2;
        }

        .block.block_banner {
            margin-bottom: $block_margin*2;

            border-radius: 0;

            .panel.panel-default {
                border-radius: 0;
                border: none;
                margin: 0;
            }

            .slider-container,
            img {
                border-radius: 0;
            }

            .layer .image {
                border-radius: 0;
            }
        }

        .block.block_image {
            .panel-heading {
                width: calc(100% - #{$block_margin*4});
            }
        }
    }
}
