inline-block-paginator {
    display: block;
    width: 100%;
    padding-top: 5px;

    &.panel-footer {
        border-top: 1px solid #ddd;
        border-bottom: transparent;
        padding-bottom: 0;

        feed-reply-block.last {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    .load-previous, .load-next {
        display: block;
        background-color: transparent;
        border-radius: 0;
        text-align: left;
        border: none;
    }

    .btn.btn-default.load-previous {
        margin: -10px -15px 5px -15px;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
    }

    .btn.btn-default.load-next {
        border-top: 1px solid #f3f3f3;
        margin: 1px -15px 0 0;
        padding: 5px;
        font-size: 0.9em;
    }

    & inline-block-paginator {
        .load-next {
            margin-top: -5px;
        }

        .btn.btn-default.load-previous {
            margin: -5px -15px 0 -15px;
            border-bottom: 1px solid #ddd;
            border-top: 1px solid #ddd;
            font-size: 0.9em;
            padding: 5px 5px 5px 15px;
        }
    }

    .icon-loader {
        display: block;
        margin: 0 auto;
    }
}
