app-search {
    display: block;
    width: 10%;
    min-width: 200px;
    transition: width ease-in .2s;

    &:focus-within {
        width: 45%;
    }
}

.app-search {
    $light-secondary-color: #7D8082;
    $light-btn-background: #F3F3F3;
    $light-input-color: #18191A;
    $accent-color: #007AFF;

    margin: 6px 0;

    &.dark {
        .search-input-wrapper {
            &:hover {
                background-color: rgba(#3F4041, .8);
            }

            &:focus-within {
                background-color: #3F4041;

                input {
                    color: #E4E6EB;
                }
            }
        }

        button {
            color: #B1B3B8;

            &.clear {
                background-color: #252627;
            }
        }
    }

    &.dark, &.light { // Not an error, there's a third state (default) that is 'blank'
        input {
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #B1B3B8;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #B1B3B8;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #B1B3B8;
            }
        }
    }

    &.light {
        .search-input-wrapper {
            border-color: #B1B3B8;
        }
    }

    .search-input-wrapper {
        background-color: rgba(255, 255, 255, .2);
        padding: 3px;
        border-radius: 16px;
        height: 32px;
        border: 1px solid transparent;
        transition: background-color ease-in .2s;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, .4);
        }

        &:focus-within {
            background-color: rgba(255, 255, 255, 1);
            border-color: $accent-color;
            box-shadow: 0px 0px 0px 1px rgba(0, 122, 255, 0.3);

            button {
                opacity: 1;
                color: $light-secondary-color;
            }

            input {
                color: $light-input-color;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $light-secondary-color;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $light-secondary-color;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: $light-secondary-color;
                }
            }
        }
    }

    input {
        background-color: transparent;
        border: 0;
        line-height: 24px;
        padding: 0;
        width: calc(100% - 56px);
        margin: 0 4px;

        &:focus-visible {
            outline: none;
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #fff;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #fff;
        }
    }

    input[type=search]::-ms-clear,
    input[type=search]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    button {
        width: 24px;
        height: 24px;
        line-height: 24px;
        border: 0;
        background-color: transparent;
        padding: 0;
        border-radius: 12px;
        outline: none;
        text-align: center;
        transition: color ease-in .2s, opacity ease-in .2s;
        opacity: 1;

        &.search {
            float: left;
        }

        &.clear {
            opacity: 0;
            background-color: $light-btn-background;

            &:hover {
                opacity: .6;
            }
        }

        &:focus {
            box-shadow: 0px 0px 0px 1px $accent-color;
        }
    }

    i {
        font-size: 20px;
        height: 24px;
        line-height: 22px;
        display: inline-block;
        vertical-align: middle;
    }
}
