.block_feed_topic {

    .panel-heading {
        border-bottom: 0;
        min-height: 35px;
    }

    .footer-post-actions-block {
        border-bottom: 1px solid #ddd;
        padding: 8px 0 16px;

        a {
            cursor: pointer;
            margin-right: 32px;
            font-size: 12px;
        }
        .dark-mode & {
            border-bottom-color: #484D51;
        }
    }
}
