.modal-header {
    @include clearfix;
}

.modal.modal-sheet{
    .modal-header {
        padding: 12px;
    }
    .modal-message {
        padding: 12px;
    }

    &.menu .modal-content {
        border-top: 0;
    }

    .nav-content {
        padding-top: 16px;
    }

    .nav-content:has(.panel-form) {
        padding: 0;
    }
}
