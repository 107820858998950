html,
body {
    font-size: $font-size-base;

    &[lang^="ar"] {
        direction: rtl;
    }
}

body {
    @include font-smoothing();
    @include font-size($font-size-base);
    background-color: $body-bg;

    padding: $body-padding 0;
}

img {
    max-width: 100%;
}

// Application shell
app-chrome {
    display: block;
}

// Blocking overlay
.global-blocking-task-guard {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 90002;
}

// Components and directive display fix
agenda-nav,
blocks-list-nav,
blocks-nav,
conversation-nav,
list-nav,
scan-nav,
sheet,
sm-calendar-day-view,
fstg-block {
    display: block;
}

// Lazyloading stuff
div[lazy-background-asset-src],
div[background-asset-src] {
    min-width: 1px;
    min-height: 1px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    opacity: 0;
    transition: opacity .5s ease;
    width: 100%;
    height: 100%;

    &.asset-loaded {
        background-color: #fff;
        opacity: 1;

        span {
            display: none;
        }
    }
}


fstg-block, .fstg-block {
    @include clearfix;
}


.mobile-safari {
    .radio input[type="radio"], .radio-inline input[type="radio"],
    .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
        margin-top: 2px;
    }
}

.safari {
    [contenteditable] {
        -webkit-user-select: text;
        user-select: text;
    }
}
