.nav-document-external,
.nav-document-error {
    text-align: center;
    margin-top: 50px;
}

.nav-document-pdf-container {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    // restore box sizing for pdfjs viewer, otherwise causes some margin issues
    * {
        box-sizing: content-box;
        user-select: text;
    }
    // but not popover content
    .popover,
    .popover * {
        box-sizing: border-box;
    }

    .popover {
        width: 250px;
        max-width: 250px;
    }

    .popover.top {
        margin-top: 27px;
    }

    .popover.bottom {
        margin-top: 15px;
    }
}

.nav-document-pdf-viewer {
    display: inline-block;
    background-color: #808080;
    padding-bottom: 10px;
    min-width: 100%;
}

.nav-document-pdf-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 5px;
    box-shadow: #ddd 0 2px 15px, #c0c0c0 0 1px 2px;

    .btn i[class^='icon'] {
        vertical-align: middle;
    }

    .btn-add-note {
        &.active {
            color: $brand-primary;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.nav-document-pdf-page {
    display: inline-block;
    margin-top: 5px;

    input {
        width: 50px;
        text-align: right;
        border: none;
    }
}

.nav-document-pdf-search {
    margin-top: 5px;
    input {
        width: 120px;
        border: none;
        height: 34px;
        border-radius: 20px;
        padding: 0 10px;
        margin-top: -5px;
        margin-right: 15px;
        box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.2) inset;

        &:focus {
            outline: none;
        }

        &::-ms-clear {
            display: none;
        }

        &.no-matches {
            background-color: rgba(255, 0, 0, .2);
        }

    }
    @media (max-width: $screen-xs-max) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        z-index: 3;
        padding: 8px 10px;
        input {
            width: auto;
            margin-top: -8px;
        }

        i {
            margin-top: 2px;
        }
        &.pdf-search-active {
            display: block;
        }
    }
}

.nav-document-pdf-highlight {
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
}

.nav-document-pdf-annotation {
    position: absolute;
    background-color: yellow;
    padding: 2px 5px 0 5px;
    box-shadow: 0px 2px 15px -3px black;
    margin: 0 0 5px -12px;
    border-radius: 4px;
    cursor: pointer;

    &:before {
        position: absolute;
        bottom: -10px;
        left: 7px;
        border: solid 5px transparent;
        border-top-color: yellow;
        content: ' ';
        display: block;
    }
}

.nav-document-pdf-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.nav-document-add-annotation .page {
    cursor: copy;
}

/* pdfjs override, hide pdf annotations */
.pdfViewer .annotationLayer .textAnnotation {
    display: none;
}
