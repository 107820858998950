$border-color: #ddd;

agenda-calendar {
    display: flex;
    border-bottom: 1px solid $border-color;
    overflow: auto;
    max-height: 90px;

    .item {
        cursor: pointer;
        flex: 1 1 auto;
        border-right: 1px solid $border-color;
        text-align: center;
        padding: 15px;

        .date {
            font-weight: bold;
            font-size: 1.5em;
        }

        &.selected {
            background: rgba(0, 0, 0, 0.1);
        }

        &.disabled {
            color: #999;
            pointer-events: none;
            cursor: default;

            .day {
                font-weight: 200;
            }

            .date {
                font-weight: 300;
            }
        }

        &:last-child {
            border-right: none;
        }
    }
}
