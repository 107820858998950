/// generic styles ///
a[ng-href] {
    text-decoration: none;
    display: inline-block;
}

[ng-click] {
    cursor: pointer;

    &:not(.no-click-effect) {
        transition: background-color 0.3s ease;

        &.ng-click-active {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

/// enforce this for initial load, when angular is not yet available ///
.ng-hide {
    display: none;
}
