banner-row {
    display: block;
    cursor: pointer;

    & > div {
        position: relative;
    }

    img {
        max-width: 100%;

        &.no-src ~ .title {
            position: relative;
        }
    }

    .title {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 10px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        box-sizing: border-box;

        .media-left {
            width: 70px;
            font-size: 85%;
        }
    }
}
