nav-header {
    display: block;

    &:empty {
        display: none;
    }

    &.child-asset-loaded .nav-header {
        span {
            text-shadow: #000 0 1px 1px;
        }
    }

    .title {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        font-size: 1.2em;
    }

    [class^="ic-"], [class*=" ic-"] {
        font-size: 18px;
    }
}

@media screen and (max-width: $screen-xs-max) {
    nav-header {
        &.search-in {
            span.title {
                display: none;
            }
        }
    }

    .nav-header {
        padding: 0 15px;
    }
}
