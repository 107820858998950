@import 'calendar/calendar';
@import 'rows/banner/banner-row';
@import 'rows/regular/regular-row';
@import 'rows/master/master-row';

agenda-nav {
    .nav-content {
        display: flex;
        flex-direction: column;
        min-height: min-content;
        height: calc(100% - #{$nav-bar-height});

        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;
    }

    agenda-calendar, .agenda-nav-list-items {
        flex: 1 1 auto;
        overflow: auto;
    }

    agenda-calendar {
        flex: 1 1 90px;
        overflow-y: hidden; // IE 11;
    }

    .search-bar {
        width: 100%;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;

        input[type='text'] {
            flex-grow: 1;
            border: 0;
            padding: 5px;
        }

        .btn {
            border-radius: 0;
            border: 0;
            border-left: 1px solid #ddd;
        }
    }

    .nav-sidebar {
        .search {
            display: block;
            width: 100%;
            padding: 5px;
            border-radius: 4px;
            border: 1px solid $boxed-content-borders-color;
        }

        filtering {
            max-height: none;
            width: auto;

            .popover-footer-placeholder {
                display: none;
            }
            .popover-footer {
                position: initial;
            }
        }
    }

    .filters-applied-info {
        background-color: #999;
        color: #fff;
        padding: 5px;
    }

    .no-content {
        text-align: center;
        margin-top: 20%;
        margin-bottom: 30%;

        img {
            margin: 0 auto 1em auto;
            width: 100px;
        }

        .btn {
            margin-top: 1em;
        }
    }

    .nav-list-item {
        display: block;
        min-height: 4em;
        border-left: 2px solid transparent;

        .media-object {
            width: 4em;
            padding-top: 3px;
        }

        .media-body {
            .nav-list-item-title {
                max-height: none;
                -webkit-line-clamp: none;
            }

            .tag {
                font-size: 0.8em;
                display: inline-block;
                padding: 0px 10px;
                border-radius: 1em;
                margin-right: 5px;
                margin-top: 5px;
            }

            .inline-image {
                display: inline-block;
                width: 32px;
                height: 32px;
                line-height: 32px;
                overflow: hidden;
                border-radius: 50%;
                text-align: center;
                background-color: $spotme-light-grey;
                color: #fff;
                margin-top: 5px;
                margin-right: 5px;
                vertical-align: middle;

                &.child-asset-loaded span {
                    display: none;
                }
            }
        }
    }

    component-loader:last-child {
        .nav-list-item {
            border-bottom: 0;
        }
    }

    .live {
        border-left: 2px solid #FF3E33;
    }

    .past:not(.live) {
        background-color: var(--background-color-4);
    }

    .live-badge {
        margin-bottom: 4px;

        span {
            background-color: #FF3E33;
            color: #fff;
            display: inline-block;
            padding: 0 4px;
            border-radius: 2px;
            font-size: 12px;
            line-height: 16px;

            $bullet-size: 6px;

            em {
                font-size: 0;
                vertical-align: middle;
                display: inline-block;
                margin-top: math.div($bullet-size, -2);
                height: $bullet-size;
                width: $bullet-size;
                background-color: #fff;
                border-radius: 50%;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .mobile-safari agenda-nav {
        .agenda-nav-list-items {
            bottom: 0;
        }
    }
}
