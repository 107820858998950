in-app-notification {
    display: block;
}

.in-app-notification {
    position: fixed;
    z-index: $in-app-notification-z-index;

    border-radius: 4px;

    width: $in-app-notification-width;

    top: $body-padding;
    right: $base-margin;

    padding: $base-padding;

    animation-name: slideInFromRight;
    animation-duration: .5s;
    animation-fill-mode: forwards;

    &.destroyed {
        animation-name: slideOutToRight;
    }

    &.has-action {
        cursor: pointer;
    }

    display: flex;

    .icon-wrapper {
        margin-right: $base-margin;

        i[icon] {
            display: block;
            width: $in-app-notification-icon-size;
            height: $in-app-notification-icon-size;
            background-size: contain;
        }
    }

    .text-wrapper {
        flex: 1;
        overflow: hidden;

        .heading {
            letter-spacing: -0.4px;
            font-size: $font-size-large;
            line-height: 1.29;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .message {
            font-size: $font-size-base;
            letter-spacing: -0.1px;
        }

    }
}
