.join-call-btn {
    display: block;
    width: 100%;
    line-height: 24px;
    border-radius: 4px;
    background-color: #4BD964;
    cursor: pointer;
    color: #fff;
    padding: 8px 12px;
    margin: 0 0 16px 0;

    .icon-phone {
        vertical-align: middle;
        margin-right: 5px;
        background: #fff;
        color: #77D672;
        border-radius: 50%;
        padding: 5px;
    }
}
