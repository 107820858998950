.nav-scan {
    $qr-size: 100px;


    .nav-content {
        background: $nav-content-background-color;
        border-radius: 4px;
        border: 1px solid #DDDDDD;
    }

    .header {
        .panel {
            margin: 0 0 15px 0;
        }
    }

    .header,

    .pax-profile.row-person {
        padding: 15px 15px 15px 20px;
        line-height: 1em;
        transition: opacity 0.3s ease;

        .media-left {
            padding-right: 20px;
        }
    }

    .profiles-separator {
        margin: 0;
        border-color: rgba(0, 0, 0, 0.1);
    }

    .media.media-codes {
        margin-top: 0;
    }

    .pax-bcx-codes {
        margin-top: 0;
        height: $qr-size;

        .media-left {
            width: $qr-size + 10;
            height: $qr-size;
            text-align: center;

            .qr-code {
                display: block;
                margin: 0 auto;
                height: $qr-size;
                width: $qr-size;
                transition: transform 0.3s $ease-in-out-quad;
                transform-origin: 0% 100%;
                cursor: pointer;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }

        .media-body {
            .tmp-code {
                font-family: monospace;
                font-size: 300%;
                line-height: $qr-size;
                vertical-align: middle;
            }
        }
    }

    .scan-form {
        input[type='text'] {
            padding: 0;
            margin: 0;
            line-height: 1em;
            font-family: monospace;
            font-size: 150%;
        }

        &.disabled {
            button {
                outline: none;
                border: none;
                opacity: 0.7;
            }
        }
        &.invalid {
            input {
                color: $state-danger-text;
            }
        }
    }

    .scanned-cards {
        .group {
            width: 100%;
            float: left;
            margin: 0;
        }

        .group:last-child {
            .nav-list-item {
                border-bottom: none;
            }
        }

        .groups-container {
            @include clearfix;
            margin: 0 0 0 -7.5px;
            animation-duration: 0.3s;
        }

        .scanned-card {
            margin-left: 7.5px;
            margin-bottom: 0;
            border: none;
        }
    }

    .input-group {
        z-index: 0;
    }
}

.bcx-qr-sheet {
    .qr-code,
    .tmp-code {
        display: block;
        margin: 15px auto;
    }
    .qr-code {
        width: 200px;
        height: 200px;
    }
    .tmp-code {
        text-align: center;
        font-family: monospace;
        font-size: 300%;
        line-height: 1em;
    }
}

@media (max-width: $screen-xs-max) {
    .nav-scan {
        .scanned-cards {
            .group,
            .grid-sizer {
                width: 100%;
            }
        }
    }
}
