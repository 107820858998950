profile-row {
    display: block;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 0 10px 0 !important;
    transition: opacity 0.3s ease, transform 0.5s ease;

    .bg-wrapper.with-bg {
        .user-info {
            .avatar {
                margin-top: -40px;
            }
        }
    }

    .bg-wrapper:not(.no-actions) {
        &:hover .nav-list-item-title {
            text-decoration: underline;
        }

        cursor: pointer;
    }

    img.banner {
        width: 100%;
        min-height: 70px;
    }

    .user-info {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        // chrome rendering issue with img border
        position: relative;

        .avatar {
            margin-bottom: 5px;
        }
    }

    .nav-list-item-title {
        vertical-align: middle;
    }

    .nav-list-item-subtitle {
        margin: 0;
        opacity: 0.8;
    }

    .nav-list-item-title,
    .nav-list-item-subtitle {
        color: inherit;
    }
}
