@import 'chat-details/chat-details';

$conversation-nav-footer-height: 51px;
$video-call-height: 506px;
$conversation-nav-height: calc(100vh - #{$body-padding * 2});
$difference-nav-content-nav: calc(#{$conversation-nav-footer-height} + 38px);
$conversation-nav-content-height: calc(#{$conversation-nav-height} - #{$difference-nav-content-nav});

conversation-nav {
    .nav-conversation {
        position: relative;
        height: $conversation-nav-height;
        overflow: hidden;
        min-height: 250px;

        .nav-content {
            left: 0;
            right: 0;
            padding: 7.5px 0;
            margin: 0 auto;
            overflow: auto;
            height: $conversation-nav-content-height;

            border: 1px solid #ddd;
            border-radius: 4px;
            min-height: calc(250px - #{$difference-nav-content-nav});
        }

        chat-details, .chat-details, .invites {
            .nav-content {
                border: 0;
            }
        }

        .chat-info-button {
            i {
                font-size: 22px;
            }
        }
    }


    &.group-chat {
        .nav-content .conversation-row .media-head .sender {
            display: inline-block;
        }
    }

    .nav-footer {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .input-group-btn {
            animation-duration: 0.3s;
        }

        textarea.form-control {
            margin: 0;
            width: 100%;
            padding: 5px;
            border-radius: 4px;
            height: $conversation-nav-footer-height;
            z-index: 0;
        }

        .btn {
            padding-left: 20px;
            padding-right: 20px;
            font-size: 24px;
        }
    }

    div[ng-include],
    div[ng-include] > .conversation-row {
        height: auto;
    }

    .notification:not(.callstart) {
        text-align: center;

        &.date {
            background-color: transparent;
        }

        &.event {
            opacity: 0.8;
        }

        &.callstart {
            text-align: right;
        }

        .message {
            font-size: 13px;
            line-height: 1;
        }
    }
}

.conversation-row {
    $own-bubble-color: rgb(153, 153, 153);
    position: relative;
    margin: 16px 0;
    padding: 0 16px;

    &.event .message {
        padding: 4px 12px;
        border-radius: 4px;
    }

    &.own {
        text-align: right;

        .message {
            background-color: $own-bubble-color;
            color: #fff;
            border-radius: 16px;
        }
    }

    &.callstart, &.callpart, &.callstop, &.calljoin {
        .name {
            display: none;
        }

        .message {
            padding: 8px 12px;
        }

        .text > i {
            margin-right: 8px;
            vertical-align: middle;
        }
    }

    &.own, &.callstart, &.callpart, &.callstop, &.calljoin {
        .timestamp + .message {
            border-top-right-radius: 4px;
        }

        &.group-with-prev .message {
            border-top-right-radius: 4px;
            border-top-left-radius: 16px;
        }

        &.group-with-next .message {
            border-bottom-right-radius: 4px;
        }
    }

    &.their {
        .timestamp + .message {
            border-top-left-radius: 4px;
        }

        .message {
            border-radius: 16px;
        }

        &.group-with-prev .message {
            border-top-left-radius: 4px;
            border-top-right-radius: 16px;
        }

        &.group-with-next .message {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 16px;
        }
    }

    &.notification {
        font-size: 13px;
    }

    &.date .message {
        background-color: transparent;
    }

    &.group-with-prev {
        margin-top: -12px;

        .timestamp, .name {
            display: none;
        }

        .message {
            border-top-left-radius: 4px;
        }
    }

    .timestamp {
        font-size: 11px;
        color: rgb(155, 155, 155);
        margin-bottom: 4px;
    }

    .message {
        font-size: 15px;
        line-height: 20px;
        padding: 8px 12px;
        display: inline-block;
        text-align: left;
        border-radius: 16px;
        background-color: rgb(246, 246, 246);
        max-width: 80%;
    }

    .name {
        color: rgb(74, 74, 74);
        display: block;
        font-weight: bold;
        margin-bottom: 4px;
        cursor: pointer;
    }

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .actions {
        padding: 4px 0;
    }
}

.row-conversationmessage {
    .nav-list-item-subsubtitle {
        margin-top: 5px;
    }

    .notification {
        width: 14px;
        height: 14px;
        opacity: 0;
        background: red;
        float: right;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        margin-left: 48px;
        margin-top: 4px;
        border: 2px solid #fff;
        transform: scale(0);
        transition: opacity 0.3s ease, transform 0.6s ease;
    }

    &.status-unread {
        .notification {
            opacity: 1;
            transform: scale(1);
            background: #f00;
        }
    }
}

sheet conversation-nav .nav-content {
    top: 0;
    height: calc(100vh - #{$body-padding * 2});
}
