conversation-nav {
    .nav-conversation {
        height: calc(100vh - #{$nav-header-height});
        margin-top: $nav-header-height;
    }

    .nav-content {
        height: calc(100vh - #{$nav-header-height} - 48px - #{$conversation-nav-footer-height});
    }
}
