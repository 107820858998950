.test-watermark {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    z-index: 10000;
    pointer-events: none;
    background-color: rgba(red, .2);
    color: red;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;

    p {
        margin: 0;
    }
}
