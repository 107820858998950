$video-call-z-index: 12;

moderator-options {
    position: absolute;
    right: 0;
    top: 0;
    z-index: $video-call-z-index + 10;

    .button-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .button-wrapper {
            margin: 4px 8px 4px 0;
            width: 24px;
            height: 24px;

            button.show-options, .pin-speaker, .unpin-speaker {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 12px;
                }
            }
        }
    }

    button {
        border: none;
        background-color: transparent;
        border-radius: 4px;
    }

    button:hover {
        background-color: #3F4041;

        .action-tooltip {
            visibility: visible;
            transition-delay: 0.2s;
        }
    }

    .show-options, .options, .pin-speaker, .unpin-speaker {
        position: absolute;
        z-index: $video-call-z-index + 3;
        border-radius: 4px;
    }

    .show-options, .options, .pin-speaker {
        background-color: #484D51;
    }

    .unpin-speaker {
        background-color: #3587FB;

        &:hover{
            background-color: #0b6efa;
        }
    }

    .options-wrapper {
        direction: rtl;
        margin-right: 8px;
    }

    .options {
        top: 24px;
        right: 8px;
        margin-top: 8px;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            .option {
                padding: 4px;
                width: 100%;
                text-align: left;
                display: flex;
                align-items: center;

                i {
                    margin-right: 8px;
                    margin-left: 8px;
                }

                span {
                    margin-right: 4px;
                    white-space: nowrap;
                }
            }
        }
    }
}
