/**
 * Applies the font smoothing
 */
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/**
 * Sets the font for this weight.
 *
 * @param $family the font family to set.
 * @param $weight one of 'bolder', 'heavy', 'bold', 'semibold', 'normal' (default),
 *        'light', 'lighter' or 'thin'
 */
@mixin application-font($family, $weight: 'normal') {

  @include font-smoothing();

  // We use this kind of mapping in order to keep keywords
  // bound to the Google Web Fonts standard imports.
  @if ($weight == 'bolder') {
    $weight: 900;
  }

  @if ($weight == 'heavy') {
    $weight: 800;
  }

  @if ($weight == 'bold') {
    $weight: 600;
  }

  @if ($weight == 'semibold') {
    $weight: 500;
  }

  @if ($weight == 'normal') {
    $weight: 400;
  }

  @if ($weight == 'light') {
    $weight: 300;
  }

  @if ($weight == 'lighter') {
    $weight: 200;
  }

  @if ($weight == 'thin') {
    $weight: 100;
  }

  font-family: $family;
  font-weight: $weight;
}

/**
 * Sets the primary font for this weight.
 *
 * @param $weight one of "light", "normal" (default), "bold" or "bolder" (if provided by font).
 */
@mixin primary-font($weight: 'normal') {
  @include application-font($primary-font, $weight);
}

/**
 * Sets the secondary font for this weight.
 *
 * @param $weight one of "light", "normal" (default), "bold" or "bolder" (if provided by font).
 */
@mixin secondary-font($weight: 'normal') {
  @include application-font($secondary-font, $weight);
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculate-rem($size);
}

@mixin line-height($lh) {
  line-height: $lh;
  line-height: calculate-rem($lh);
}

/**
 * Cross-browser support for @font-face. Supports IE, Gecko, Webkit, Opera.
 *
 * $name is required, arbitrary, and what you will use in font stacks.
 * $font-files is required using font-files('relative/location', 'format'). for best results use this order: woff, opentype/truetype, svg
 * $eot is required by IE, and is a relative location of the eot file.
 * $weight shows if the font is bold, defaults to normal
 * $style defaults to normal, might be also italic
 * For android 2.2 Compatiblity, please ensure that your web page has a meta viewport tag.
 * To support iOS < 4.2, an SVG file must be provided
 */
@mixin font-face($name, $font-files, $eot: false, $weight: false, $style: false) {
  $iefont: unquote("#{$eot}?#iefix");
  @font-face {
    font-family: quote($name);
    @if $eot {
      src: font-url($eot);
      $font-files: font-url($iefont) unquote("format('embedded-opentype')"), $font-files;
    }
    src: $font-files;
    @if $weight {
      font-weight: $weight;
    }
    @if $style {
      font-style: $style;
    }
  }
}


// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}


// Text overflow
// Requires inline-block or block for proper styling

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
 * Creates the style for the thumb of the slider.
 *
 * NOTE: we use a mixin because selector concatenation doesn't work in this case.
 */
 @mixin slider-thumb {
  appearance: none;
  background-color: white;
  height: 16px;
  width: 16px;
  box-shadow: 0 0 $half-unit rgba(0, 0, 0, .25);
  border-radius: 50%;
}