.nav-blocks-list {
    .new-content {
        position: fixed;
        text-align: center;
        right: 0;
        margin: 0 auto;
        margin-left: 240px;
        left: 0;
        z-index: -1;
        top: 72px;
        opacity: 0;
        transition:
            opacity .3s ease-in-out,
            z-index .3s ease-in-out;

        &.in {
            opacity: 1;
            z-index: 10;
        }

        a {
            border-radius: 20px;
            padding: 8px 16px;
            font-weight: 700;

            &:hover {
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}

.search-tool {
    line-height: 1;
    float: right;

    &.in {
        input {
            width: 200px;
            padding: 0 14px;
        }

        button.btn {
            max-width: 120px;

            img,
            i {
                opacity: 0;
            }
            span.text {
                opacity: 1;
            }
        }
    }

    .search-blocks-list-form {
        display: inline-block;
        position: relative;
        overflow: hidden;
    }

    input {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        border: none;
        height: 22px;
        margin-right: 10px;
        padding: 0;
        transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
        width: 0;
        line-height: 22px;
        font-weight: normal;

        &:focus {
            outline: none;
        }
    }

    button.btn {
        vertical-align: top;
        background-color: transparent;

        &:focus {
            outline: none;
        }

        &:active {
            box-shadow: none;
        }

        img,
        i,
        span.text {
            transition: opacity 0.3s ease-in-out;
        }

        span.text {
            display: inline-block;
            opacity: 0;
            margin-right: -22px;
        }
    }
}
