$default-margins: 8px;
$button-paddings: 8px;
$default-color: #808080;
$nested-card-margins: 2px;

card,
component-loader,
.widget {
    display: block;
}

// affects blocks nested inside a carousel
.nav-blocks .blocks-root .block .card-block .card-block {
    border-radius: $nested-card-margins;
    margin: $nested-card-margins;
    box-shadow: none;
    border: 0;
}

card.single {
    text-fragment{
        p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

}

.card-block {
    @extend .panel;
    @extend .panel-default;
    display: block;
    overflow: hidden;

    &.no-card {
        box-shadow: none;
    }

    &.no-action {
        cursor: auto;
    }

    picture {
        display: block;
        height: 100%;

        img {
            width: auto;
        }
    }

    p {
        margin: 0;
    }

    .widget {
        > div {
            margin: $default-margins;
            font-size: 14px;
        }
    }

    .card-block {
        border-radius: $nested-card-margins;
        box-shadow: none;
        margin: $nested-card-margins;
    }

    .actions-bar,
    .carousel,
    .divider,
    .media-text,
    .text,
    .image {
        span.title {
            font-weight: bold;
            overflow: hidden;
            font-size: 18px;
            line-height: 1.4;
        }
    }

    image-fragment {
        text-align: center;
    }

    figure.image {
        position: relative;
        display: inline-block;
        overflow: hidden;

        &.circle {
            border-radius: 100%;
        }

        img {
            width: auto;
            max-height: 100%;
        }

        figcaption {
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            padding: 10px;
            color: #fff;

            > span {
                display: block;
            }
        }

        span.title {
            font-size: 24px;
            max-height: 2.8em;
            margin-bottom: 10px;
        }
    }

    .actions-bar {
        .left,
        .right {
            display: inline-block;
            margin-left: $button-paddings * -1;
            margin-right: $button-paddings * -1;
        }

        .right {
            float: right;
        }

        .left {
            float: left;
        }

        button {
            padding: $button-paddings;
            margin: 0;
            background: transparent;
            border: 0;
            font-weight: bold;

            i.icon {
                background-size: cover;
                width: 24px;
                height: 24px;
                display: inline-block;
                vertical-align: middle;
            }

            .label {
                vertical-align: middle;
            }
        }
    }

    .carousel {
        > span.label {
            float: right;
            display: inline-block;
        }

        .viewport {
            overflow: auto;
            padding-bottom: 15px;
            -webkit-overflow-scrolling: touch;
        }

        .items-container {
            white-space: nowrap;
            display: flex;
        }

        .item {
            white-space: normal;
            display: inline-block;
            flex-shrink: 0;
        }

        $outer-above-the-fold: 30px;
        .outer {
            overflow: hidden;
            margin-bottom: $outer-above-the-fold * -1;
            position: relative;

            &:hover .arrow {
                opacity: 1;
            }
        }

        $arrow-size: 10px;
        $arrow-thickness: 3px;

        .arrow {
            opacity: .3;
            transition: opacity ease-in-out .4s;
            position: absolute;
            top: 50%;
            margin-top: math.div( $arrow-size, -2 ) - $outer-above-the-fold;
            border-radius: 100%;
            width: $arrow-size * 2 + 4 ;
            height: $arrow-size * 2 + 4;
            cursor: pointer;
            z-index: 2;

            &:after {
                content: '';
                display: block;
                width: $arrow-size;
                height: $arrow-size;
                border-left: $arrow-thickness solid;
                border-bottom: $arrow-thickness solid;
                border-color: inherit;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -6px;
                margin-left: -4px;
            }

            &.left {
                transform: rotate(45deg);
                left: $arrow-size;
            }

            &.right {
                transform: rotate(-135deg);
                right: $arrow-size;
            }
        }
    }

    .divider {
        min-height: 1px;
        padding: 0;
        margin: $default-margins;
        background-color: $default-color;
    }

    .media-text {
        @include clearfix;
        display: flex;
        align-items: center;

        .text {
            flex: 1 1 auto;
        }

        > .image-wrapper {
            flex: 0 0 auto;
            float: left;
            margin-right: 15px;

            > .image {
                visibility: hidden;
                &.asset-loaded {
                    visibility: visible;
                }
            }
        }

        > .text {
            > span {
                display: block;

                &.subtitle {
                    font-size: 16px;
                }
            }

            .sidetitle {
                float: right;
            }
        }
    }

    .tag-list {

        .outer {
            padding: 6.5px 0;
            margin-left: -8px;
            margin-right: -8px;
            margin-bottom: 0;
            height: 45px;
        }

        .viewport {
            height: 55px;
            padding-bottom: 5px;
        }


        .arrow {
            margin-top: -12px;
        }

        .tag {
            text-transform: uppercase;
            font-size: 14px;
            margin: 0 5px;
            border-radius: 25px;
            overflow: hidden;
            border: 2px solid transparent;
        }

        span.text {
            padding: 5px 10px;
            display: inline-block;
        }

        .items-container {
            .item {
                max-width: 300px;
                span.text {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

    }

}
