@import 'pdfjs-dist/web/pdf_viewer';
@import 'quill/dist/quill.snow';

$abdtp-width:                    320px !default;
$abdtp-color-hover:              #eee !default;
$abdtp-color-disabled:           #ebebeb !default;
$abdtp-active-background-color:  #04c !default;
$abdtp-active-color:             #fff !default;
$abdtp-current-background-color: #e5e5e5 !default;
$abdtp-color-past-future:        #999 !default;

.datetimepicker {
  direction: ltr;
  display: block;
  margin-top: 1px;
  user-select: none;
  width: $abdtp-width;

  > div {
    display: none;
  }

  .hour,
  .minute {
    height: 34px;
    line-height: 34px;
    margin: 0;
    width: 25%;
  }

  .table {
    margin: 0;
  }

  .table td,
  .table th {
    border: 0;
    height: 20px;
    text-align: center;
  }

  .minute:hover,
  .hour:hover,
  .day:hover,
  .switch:hover,
  .left:hover,
  .right:hover {
    background: $abdtp-color-hover;
    cursor: pointer;
  }

  .disabled,
  .disabled:hover {
    background: none;
    color: $abdtp-color-disabled;
    cursor: default;
  }

  .current,
  .current:hover,
  .current.disabled,
  .current.disabled:hover {
    background-color: $abdtp-current-background-color;
  }

  .active,
  .active:hover,
  .active.disabled,
  .active.disabled:hover,
  .table > tbody > tr > td.active,
  .table > tbody > tr > td.active.disabled,
  .table > tbody > tr > td.active.disabled:hover {
    background-color: var(--contrast-color, #{$abdtp-active-background-color});
    color: $abdtp-active-color;
  }

  span {
    cursor: pointer;
    display: block;
    float: left;
    height: 54px;
    line-height: 54px;
    margin: 1%;
    width: 23%;

    &:hover {
      background: $abdtp-color-hover;
    }
  }

  .past,
  .future {
    color: $abdtp-color-past-future;
  }
}

.ql-snow {
  &.ql-toolbar, &.ql-container {
    border: 0;
  }
}

ng-quill-editor, ng-quill-toolbar {
  display: block;
}
