.nav-wrapper {
    padding-bottom: 60px;
}

.grid-layout {
    .media-object {
        .initials {
            font-size: 3rem;
        }
      }
}
