.block_feed_comment {

    .ctx-button {
        font-size: 18px;
        line-height: 32px;
        vertical-align: middle;

        img {
            max-width: 20px;
            height: 20px;
        }
    }

    .panel-author .media {
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        cursor: pointer;
    }

    .panel-media + feed-post-attachments {
        margin-top: 16px;
    }

    .moderator-actions {
        @extend %footer-actions-bar;

        .action {
            @extend %footer-action;
            width: 50%;
            text-align: center;
            padding: 8px;

            &:hover {
                background-color: rgb(230, 230, 230);
            }

            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }

            &.selected {
                background-color: #ddd;
            }
        }
    }

    .preposition {
        font-weight: normal;
    }
}
