.row-universal-search {
    transition: transform .3s ease;
    margin-top: 0;
    margin-bottom: 10px;
    cursor: default;

    .progress-bar {
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
    }

    .input-group-addon {
        opacity: 0;
        border-radius: 4px;
        cursor: default;
        padding-right: 5px;
        background: transparent !important;
    }

    input {
        width: 100%;
        background: transparent;
        // background: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        padding: 1px 5px;
        transition: background .3s ease;
        font-size: inherit;
        line-height: inherit;
        margin: 0;
        height: 32px;
        position: relative;

        &:focus {
            background: #fff;
            color: $spotme-grey;
        }
    }

    &.search-focused {
        transform: translateY(-40px);

        .input-group-addon {
            opacity: 1;
            display: table-cell;
            cursor: pointer;
        }
    }

    .search-placeholder {
        position: absolute;
        margin-top: 2px;
        margin-left: 10px;
        opacity: 0.7;
    }
}

.universal-search-results-overlay {
    margin-top: -50px;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    -webkit-overflow-scroll: touch;

    small {
        display: block;
        text-align: center;
        opacity: 0.5;
        font-style: italic;
        margin-top: 20px;
    }

    .row-separator {
        display: block !important;
    }

    .row-search-result-group {
        animation-duration: .3s;
    }

    .group-header {
        text-indent: 10px;
        color: #ddd;
        background: #3B3B3B;
        border-bottom-color: #3B3B3B;
        margin-top: 0;
        padding-top: 20px;
        margin-bottom: 0;

        &.first.last {
            height: 1px;
            overflow: hidden;
            padding: 0;
        }
    }

    .nav-list-item {
        margin-top: 0;

        .media-body {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-height: 2em;
            display: block;
        }
    }

    .loading {
        margin-top: 20px;
        text-align: center;
        opacity: 0.7;
    }
}
