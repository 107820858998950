footer-buttons {
    display: block;
    
    .animated {
        animation-duration: .3s !important;
    }

    &[labels-only] {
        .icon {
            display: none;
        }
    }
}
