select-block {
    display: block;
}

.nav-blocks .blocks-root .block .select-block-panel {
    margin: 0 0 $base-margin;

    .select-block {
        margin: 0 0 math.div($base-margin, 4);

        .selector {
            font-size: 16px;
            padding: 10px;
            cursor: pointer;

            &.pristine {
                color: #999;
            }

            i {
                float: right;
                margin-top: 3px;
            }
        }
    }

    .required-text {
        color: #7D8082;
    }
}
