.row-person {
    display: flex;

    .grid-layout & {
        flex-direction: column;
    }

    .media-left, .media-right, .media-body {
        flex: 1 1 auto;
        align-self: center;
    }

    .media-left {
        flex-grow: 0;
    }

    .nav-list-item-subsubtitle {
        line-height: 1.2;
    }
}
