upload-block {
    display: block;
    margin: math.div($base-margin, 2)  0;

    .upload-control {
        label {
            margin: 0;
            padding: 0;

            input[type='file'] {
                width: 1px;
                height: 1px;
                opacity: 0;
                position: absolute;
                left: -1px;
                top: -1px;
            }

            .btn-content {
                display: flex;
                align-items: center;
                padding: math.div($base-padding, 2);

                i {
                    font-size: 25px;
                }

                span {
                    line-height: $base-unit * 4;
                    margin-left: $half-unit;
                }
            }
        }

        .btn-transparent {
            border-radius: 4px;
        }
    }

    .upload-preview {
        position: relative;
        margin: 10px 0 0 0 !important;
        display: inline-block;

        .remove {
            position: absolute;
            right: 5px;
            top: 5px;
            padding: 0;
            font-size: 20px;
            z-index: 1;

            i {
                background: #fff;
                border-radius: 50%;
                height: 24px;
                width: 24px;
                line-height: 22px;

                &::before {
                    vertical-align: middle;
                }
            }
        }

        img {
            max-width: 50vw;
            max-height: 20vh;
            border-radius: 4px;
            animation-duration: .3s;
        }
    }
}
