feed-post-attachments {
    display: block;
    padding: 0 15px;

    &.no-title {
        padding: 15px 15px 0 15px;
    }

    .attachment {
        border-radius: 4px;
        margin-bottom: 10px;
        background: #fff;

        &.first {
            margin-top: 5px;
        }

        &.last {
            margin-bottom: 5px;
        }

        .media {
            background-color: rgba(0,0,0,0.05);
            border-radius: 4px;
            cursor: pointer;
        }

        .media-left {
            // 10px for margins
            width: 70px;
            height: 60px;
            overflow: hidden;
            padding-left: 10px;
        }

        .media-object {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            width: 100%;
            background-color: transparent;
        }

        .media-body {
            padding: 10px 0;
            vertical-align: middle;

            &.no-media-object {
                padding-left: 10px;
            }
        }
    }
}
