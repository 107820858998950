.nav-panels {
    margin-top: $nav-bar-height + 14px;
}

.nav-content {
    border-radius: 0;
    padding-top: 10px;

    agenda-nav & {
        padding-top: 0;
    }
}

nav-header .nav-header {
    padding: 10px 15px;
    margin: 0;
}

.nav-spotman profile-row .bg-wrapper {
    padding-top: 42px; // the height of the app-nav-bar
}
