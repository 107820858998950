regular-row {
    display: block;

    .media-body {
        width: 100%;
    }

    .media-right {
        vertical-align: middle;

        i.icon-check {
            display: block;
            width: 1.5em;
            height: 1.5em;
            line-height: 1.4em;
            text-align: center;
            vertical-align: middle;
            background-color: #4c9c3b;
            color: #fff;
            border-radius: 50%;
        }
    }
}
