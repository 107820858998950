// Application loading activity indicator
#app-preloader {
    width: 100%;
    top: 30%;
    left: 0;
    position: fixed;
    text-align: center;

    .progress-bar {
        width: 200px;
        max-width: 500px;
        margin: 0 auto;

        .bar {
            animation-delay: 0.5s;
        }
    }

    .preloader-error {
        display: none;
        margin-top: 20px;
        font-size: 1.2rem;
        line-height: 1.6rem;
        animation-delay: 1s;

        small {
            display: block;
            margin-top: 20px;
        }
    }
}

/// loading screen ///
.global-progress-bar-container {
    position: relative;
}

.global-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90001;
    animation-delay: 0;
}
