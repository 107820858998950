.nav-document-note {
    width: 100%;
    min-height: 80px;
    border: none;
    padding: 0;
    margin: 0;
    resize: none;
}

.nav-document-note-color {
    padding: 0 10px 5px 10px;
    border-bottom: 1px solid #ddd;
    margin: 0 -10px 10px -10px;

    label {
        font-size: 0.9em;
    }
}
