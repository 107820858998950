@import 'frontloader-common/vendor/animate.css/sliding_entrances/slideInDown';

.nav-agenda {
    $strip-height: 80px;

    // Calendar strip
    .calendar-strip-wrapper {
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        border-bottom: 1px solid #ddd;
        background: #fff;
        height: $strip-height;
        box-sizing: border-box;
    }

    .calendar-strip {
        height: $strip-height;
        background: #fff;
        overflow-y: hidden;
        overflow-x: auto;
        // TODO width should be calculated based on the
        // number of days
        width: 100%;
        -webkit-overflow-scrolling: touch;

        .day-box {
            min-width: 50px;
            height: $strip-height;
            display: inline-block;
            border-right: 1px solid #ddd;
            text-align: center;
            padding: 5px 0;
            background: #fff;
            cursor: pointer;
            vertical-align: top;
            box-sizing: border-box;
            transition: background 0.3s ease;
            overflow: hidden;

            &.ng-click-active {
                background: rgba(0, 0, 0, 0.2);
            }

            &:last-child {
                border-right: none;
            }

            &.selected {
                background: rgba(0, 0, 0, 0.1);
            }

            &.today {
                .day,
                .date {
                    font-weight: bold;
                }
            }

            .day {
                font-size: 70%;
                color: #737373;
                text-transform: uppercase;
                text-overflow: clip;
                white-space: nowrap;
                overflow: hidden;
            }

            .date {
                font-weight: 300;
                font-size: 140%;
                margin-bottom: 5px;
            }

            .grid {
                padding: 0 5px;
                line-height: 6px;
                text-align: center;
                max-width: 66px;
                margin: 0 auto;

                .dot {
                    display: inline-block;
                    width: 100%;
                    height: 6px;
                    border-radius: 6px;
                    margin: 2px 2px 0 0;
                    border: 1px solid rgba(0, 0, 0, 0.4);
                }
            }
        }
    }

    .nav-content {
        overflow: hidden;
    }

    .sessions {
        overflow: auto;
        position: absolute;
        top: $strip-height;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
