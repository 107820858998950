.offline-notification-websocket {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #FF3E33;
    color: white;

    a {
        color: white;
        text-decoration: underline;
        &:hover, &:active, &:visited, &:link {
            color: white;
        }
    }

    button {
        background-color: rgba(0,0,0,0);
        border-radius: 4px;
        border: 1px solid white;
        margin-left: 8px;
    }

    .loading-placeholder {
        padding: 4px 20px;
        margin: 8px;
    }
}

.error-banner-visible {
    app-nav-bar {
        top: 40px;
    }
}

.offline-notification {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 11111;
    animation-duration: 4s;
    color: grey;
}

.offline-pending-call {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    z-index: 11111;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    line-height: 3rem;

    .subtitle {
        font-size: 1.8rem;
    }
}

@media (max-width: $screen-sm-max) {
    .offline-notification-websocket {
        height: 80px;
        flex-direction: column;
    }

    .error-banner-visible {
        app-nav-bar {
            top: 80px;
        }
    }
}
