%footer-actions-bar {
    border-top: 1px solid #ddd;
    padding: 0 16px;
}

%footer-action {
    color: rgb(155, 155, 155);
    font-weight: 500;
    display: inline-block;
    cursor: pointer;

    img {
        height: 32px;
    }
}

.block.block_feed_topic, .block.block_feed_comment {
    $feed-toolbar-actions-left-border-color: #e6e6e6;

    .panel > .extras {
        padding: 6px 10px;

        .icon {
            height: 15px;
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    .posted-at {
        padding: 13px 15px 10px 10px;
    }

    .panel-heading {
        border-bottom: 0;
        color: inherit;
    }

    .panel-media {
        .img {
            width: 100%;
            max-width: 100%;
            background-size: cover;
            cursor: zoom-in;
        }
    }

    .panel-body {
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: text;

        &::before, &::after {
            display: none;
        }
    }

    // padding adjustments for new .panel- elements
    .panel-heading + .panel-body {
        padding-top: 0;
    }

    .panel-author + .panel-body {
        padding-top: 10px;
    }

    .panel-body + .panel-footer.post-actions {
        padding-top: 0;
    }

    .panel-footer.toolbar-actions, .panel-footer.moderator-actions {
        background-color: transparent;
        border-top: none;

        .icon {
            width: 20px;
            height: 20px;
            background-size: contain;
        }

        i.icon {
            display: inline-block;
            vertical-align: middle;
        }

        .icon + span {
            margin-left: 8px;
        }

        .footer-block {
            display: flex;

            .toolbar-action {
                cursor: pointer;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .footer-left {
            display: flex;

            padding: 14px 0;

            .toolbar-action {
                cursor: pointer;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .panel-footer.toolbar-actions .footer-post-actions-block {
        a {
            margin-right: 16px;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        .icon + span {
            margin-left: 4px;
        }
    }

    .panel-footer.post-actions {
        background-color: transparent;
        border-top: none;

        .pull-right:after {
            clear: both;
        }

        .action {
            display: inline-block;
            cursor: pointer;
            height: 24px;
            padding: 2px 4px;
            border-radius: 4px;

            &:hover {
                background-color: rgba(0,0,0,0.05);
            }

            .animated {
                animation-duration: .3s;
            }

            .loading {
                width: 20px;
                text-align: center;
                display: inline-block;
                pointer-events: none;
                cursor: progress;
            }

            .icon-loader {
                vertical-align: middle;
            }

            .icon {
                width: 20px;
                height: 20px;
            }

            span {
                vertical-align: middle;
            }

            .icon + span {
                margin-left: 5px;
            }
        }
    }

    .preposition {
        font-weight: normal;
    }

    .thread-actions {
        float: right;
        position: relative;
        z-index: 1;
        margin-top: -26px;

        a {
            cursor: pointer;
        }
    }

    .toolbar-actions {
        @extend %footer-actions-bar;

        .actions {
            margin-right: -16px;
            margin-left: -16px;
        }

        .action {
            @extend %footer-action;
            padding: 12px 16px;
        }

        .footer-post-actions-block {
            border-bottom: 1px solid #ddd;
            padding: 16px 0;

            .dark-mode & {
                border-bottom-color: #484D51;
            }
        }

        a {
            cursor: pointer;
            margin-right: 32px;
            font-size: 12px;
        }
    }
}
