master-row {
    display: block;

    .nav-list-item {
        background-color: #f3f3f3;
    }

    & > div > .nav-list-item > .media > .media-body {
        width: 100%;
    }

    > .expanded > {
        .nav-list-item {
            border: 0;
            border-left: 2px solid transparent;
        }
    }

    .media-right {
        font-size: 1.2em;
        vertical-align: middle;
    }

    .track-expander-icon {
        &::before {
            display: block;
            transition: transform 0.3s ease;
            transform: rotate(-90deg);
        }
    }

    .track-sessions-wrapper {
        overflow: hidden;
        background-color: var(--background-color-1);
    }

    .track-sessions {
        display: none;
        border-radius: 4px;
        overflow: hidden;
    }

    .expanded {
        .track-sessions-wrapper {
            padding: 0 12px 12px;
            border-bottom: 1px solid var(--background-color-2);

            .nav-list-item:not(.past) {
                background-color: var(--background-color-3);
            }
        }

        .track-expander-icon::before {
            transform: rotate(90deg);
        }

        .track-sessions {
            display: block;
            border: 1px solid var(--background-color-2);
            animation-name: slideInDown;
            animation-duration: 0.3s;
        }
    }

}
