edit-text-block {
    display: block;

    .editor {
        width: 100%;
        padding: 10px;
        user-select: auto;
        overflow-wrap: break-word;
        font-size: 16px;
        min-height: ($base-unit * 8);
        line-height: ($base-unit * 4);
        border-radius: 4px;
        border: 1px solid #DDDDDD;

        // source: https://codepen.io/flesler/pen/AEIFc
        &:empty:before {
            content: attr(placeholder);
            color: #999;
            display: block; /* For Firefox */
        }

        &:focus {
            outline: none;
        }

        .atwho-inserted {
            border-radius: 4px;
            color: #337ab7;
            background: rgba(51, 122, 183, 0.22);
        }

        // resets styling for command+b/i/u
        b {
            font-weight: normal;
        }

        i {
            font-style: normal;
        }
    }

    input, textarea {
        width: 100%;
        background: transparent;
        border-radius: 4px;
        font-family: inherit;
        padding: 15px;
    }
}

// at.js css unfortunately it cannot be scoped
.atwho {
    &-view {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        margin-top: 1.3em;
        background: #fff;
        color: #000;
        border-radius: 4px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        min-width: 200px;
        animation-name: zoomInFromTop;
        animation-duration: .3s;
        z-index: 11110 !important;

        .cur {
            background-color: #337ab7;
            color: #fff;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: auto;
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        li {
            display: block;
            padding: 15px;
            border-bottom: 1px solid #e5e5e5;
            cursor: pointer;

            // match highlight
            strong {
                font-weight: normal;
                text-decoration: underline;
            }

            .icon {
                opacity: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                transition: opacity .3s ease;
                margin-right: 5px;

                &.loaded {
                    opacity: 1;
                }
            }

            &.cur:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom: none;

                &.cur {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }


        }

        li:last-child {
            border-bottom: none;
        }
    }

}
