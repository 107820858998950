/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
  > div > .block {
    display: block !important;
  }
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  @include clearfix;
  position: absolute;
  bottom: 13px;
  right: 10px;
  z-index: 2;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;

  li {
    float: left;
    position: relative;
    display: inline-block;
    height: $slick-dot-size;
    width: $slick-dot-size;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size;
      width: $slick-dot-size;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;
      }

      &:before {
        transition: background 0.2s linear, border-color 0.2s linear;
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, 0.3);
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -5px;
        margin-top: -5px;
        content: '';
        width: 10px;
        height: 10px;
        font-size: $slick-dot-size;
        line-height: $slick-dot-size;
        text-align: center;
      }
    }

    &.slick-active button:before {
      border-color: rgba(0, 0, 0, 0.8);
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
