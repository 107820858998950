$video-call-z-index: 12;
$video-ratio: math.div(16, 9);
$broadcast-area-max-width: 900px;
$broadcast-area-max-height: math.div(900px, $video-ratio);
$video-controls-height: 68px;
$video-wrapper-max-height: $video-controls-height + $broadcast-area-max-height;
$video-wrapper-max-width: $broadcast-area-max-width;

video-call, onboarding {
    display: block;

    .icon-mic-off {
        color: #FF3F34;
    }
}

video-call, .video-wrapper, onboarding, .onboarding {
    height: 100%;
    width: 100%;
    border-radius: 8px;

    &.full-screen {
        border-radius: 0;
    }
}

#pip-wrapper {
    &.video-call {
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-top: calc(100% / #{$video-ratio} + #{$video-controls-height});
            width: 0;
            height: 0;
        }
    }

    &.fixed-size {
        height: 480px;

        &::before {
            padding-top: 0;
        }
    }
}

video-call {
    position: absolute;
    z-index: $video-call-z-index;
    top: 0;
    left: 0;

    .video-call {
        height: 100%;
        text-align: center;
    }

    .width-viewport {
        height: 100%;
        display: inline-block;

        img {
            height: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .inner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        border-radius: 8px;
    }

    .height-viewport {
        flex: 1 1 auto;
    }

    .video-wrapper {
        background: black;
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-bottom: percentage(math.div(9, 16));
            width: 0;
            height: 0;
        }

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.dragging.pinned {
        .participants {
            visibility: hidden;
        }
    }

    .embedded-iframe iframe{
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 8px;
    }

    .error, .loading-placeholder {
        height: 100%;
    }

    .loading-placeholder {
        z-index: $video-call-z-index + 10;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .no-permissions {
        height: 430px;
        z-index: $video-call-z-index + 11;
    }

    .error {
        z-index: $video-call-z-index + 12;
    }

    .no-permissions, .error {
        padding: 190px 80px;
    }

    .no-permissions, .error, .loading-placeholder {
        text-align: center;
        background-color: black;
        color: white;
        border-radius: 8px;
        width: 100%;
        position: absolute;
    }

    .error.reconnection {
        a {
            margin: 16px;
        }
    }

    .initials-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .video-call-controls {
        z-index: $video-call-z-index + 1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: black;
        border-radius: 8px;

        .control-btn {
            position: relative;
            text-align: center;
            cursor: pointer;
            width: 88px;
            height: 52px;
            border-radius: 4px;
            padding: 4px;
            background-color: rgba(255, 255, 255, 0.1);
            color: white;
            font-size: 20px;
            margin: 8px 8px;

            .label, .count {
                font-size: 12px;
                line-height: 16px;
            }
            .count {
                position: absolute;
                top: 0;
                right: 0;
                padding: 4px 6px;
            }
            .off {
                color: #FF3F34;
            }
            .active {
                color: #2CD26A
            }
        }

        .first-control{
            margin: auto 8px auto auto;
        }

        .control-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }

        .stop-call-btn {
            background-color: #FF3F34;
            margin-left: -2px;
        }
        .stop-call-btn:hover {
            background-color: #B32B23;
        }

        .participants-btn {
            margin-left: -2px;
        }

        .selector {
            margin-left: -26px;
            margin-top: 10px;
            padding-top: 1px;
            width: 16px;
            height: 16px;
            text-align: center;
            z-index: $video-call-z-index + 2;
            color: white;
            cursor: pointer;
            border-radius: 2px;
        }

        .selector:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }

        .input-devices, .screenshare-busy {
            position: absolute;
            padding: 4px;
            text-align: left;
            bottom: 100%;
            width: 264px;
            background-color: #262627;
            color: white;
            border-radius: 8px;
            cursor: pointer;

            .input-label {
                font-size: 12px;
                color: #B1B3B8;
                padding: 8px 4px;
                box-shadow: inset 0px -1px 0px #484D51;
            }

            .device {
                font-size: 14px;
                padding: 4px;
                line-height: 24px;
            }

            .device:hover, .selected {
                background-color: rgba(255, 255, 255, 0.2);
            }

            .selected {
                border-radius: 4px;
                i {
                    margin: 0 4px;
                }
            }
        }

        .audio-devices {
            margin-left: -110px;
        }

        .video-devices, .screenshare-busy {
            margin-left: 75px;
        }

        .full-screen-btn {
            margin: auto 18px auto auto;
            font-size: 20px;
            cursor: pointer;
            z-index: $video-call-z-index + 3;
            width: 32px;
            height: 32px;
            padding: 6px;
            color: white;
        }
    }

    .video-wrapper, .onboarding-video-wrapper {
        background-color: black;
        position: relative;
        border-radius: 8px;

        &.full-screen {
            border-radius: 0;
        }

        .participants {
            z-index: $video-call-z-index;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;

            .talking-indicator {
                .talking-indicator-border {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border: 3px solid #2DD269;
                    border-radius: 8px;
                    opacity: 1;
                    z-index: $video-call-z-index + 2;
                }
                &.poster {
                    opacity: 1;
                    border: 3px solid #2DD269;
                }
            }

            publisher, subscriber {
                position: absolute;
                color: white;
                border-radius: 8px;

                .publisher-video, .subscriber-video, .blank, .invisible-overlay {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                .blank {
                    background-color: #262627;
                    border-radius: 8px;
                    z-index: $video-call-z-index + 1;
                }

                .initials-wrapper {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    .initials {
                        font-size: 64px;
                        z-index: $video-call-z-index + 1;
                    }
                }

                .OT_publisher, .OT_subscriber, .OT_video-element {
                    border-radius: 8px;
                }

                .OT_video-poster, .OT_subscriber_error {
                    // Vonage adds a display: block; in the style attribute of this element, so we need !important
                    display: none !important;
                }
            }

            &.align-items-left {
                justify-content: left;
            }

            &.darkened {
                opacity: 0.5;
            }
        }

        .screen-preview, .embedded-iframe {
            top: 18%;
            left: 5%;
            width: 90%;
            height: 80%;
            position: absolute;
        }

        .arrow {
            width: 24px;
            height: 24px;
            position: absolute;
            cursor: pointer;
            z-index: $video-call-z-index + 1;
            color: white;
            background: #252627;
            border-radius: 4px;
            border: 1px solid #484D51;

            &.left, &.screen-share-left {
                transform: rotate(180deg);
                left: 8px;
            }
            &.left, &.right {
                top: calc(50% - 12px);
            }
            &.screen-share-left, &.screen-share-right {
                top: 28px;
            }
            &.right, &.screen-share-right {
                right: 8px;
            }

            i {
                font-size: 16px;
            }
        }
        .arrow:hover {
            background-color: #515152;
        }
    }

    &.full-screen {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        background: black;

        .video-call {
            height: calc(100% - #{$video-controls-height});
            .width-viewport {
                position: relative;
            }
        }

        .inner-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .participants {
            transform: none !important;
        }

        .video-call-controls {
            position: fixed;
        }
    }

    .onboarding {
        text-align: center;
        background-color: black;
        color: white;
        border: 1px solid transparent;
        font-size: 14px;
        border-radius: 8px;

        .settings {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            .heading {
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 8px;
            }

            .help {
                line-height: 20px;
            }

            .onboarding-video-wrapper {
                video {
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }
            }

            .video, .no-video {
                height: 180px;
                width: 320px;
                z-index: $video-call-z-index;
                border-radius: 8px;
                margin-bottom: 8px;
                background-color: #252627;
            }

            .no-video {
                display: flex;
                justify-content: center;
                align-items: center;

                .initials {
                    font-size: 64px;
                }
            }

            .volume-wrapper, .camera {
                width: 48px;
                height: 48px;
                background: rgba(0, 0, 0, 0.5);
                opacity: 0.5;
                position: absolute;
                z-index: $video-call-z-index + 8;
                bottom: 10%;
                border-radius: 8px;
                font-size: 25px;
                cursor: pointer;
            }

            .volume-wrapper {
                position: absolute;
                left: calc(50% + 10px);

                .volume {
                    width: 100%;
                    height: 100%;
                    z-index: $video-call-z-index;
                    border-radius: 8px;
                }
            }

            .camera {
                left: calc(50% - 58px);
            }

            .icon-wrapper {
                position: absolute;
                left: 12px;
                top: 10px;
            }

            .onboarding-video-wrapper, .select-wrapper, .join-call-btn {
                max-width: 320px;
                border-radius: 8px;
            }

            .onboarding-video-wrapper {
                height: 180px;
                margin-bottom: 8px;
                margin-top: 8px;
            }

            .selectors {
                position: relative;
                width: 320px;
                margin: auto;

                .select-wrapper {
                    height: 40px;
                    display: flex;
                    align-items: stretch;
                    border: 1px solid #484D51;
                    margin: 0 auto 16px auto;
                    padding: 8px;
                    background-color: #252627;
                    position: relative;

                    cursor: pointer;
                    z-index: $video-call-z-index - 1;

                    .icon-mic, .icon-webcam {
                        position: absolute;
                        left: 10px;
                        top: 12px;
                    }

                    .selector {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        padding-top: 2px;
                        width: 20px;
                        height: 20px;
                        color: white;
                    }

                    .active-device {
                        position: absolute;
                        left: 30px;
                        top: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 240px;
                    }
                }
                .select-wrapper:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }

                .input-devices {
                    position: absolute;
                    margin: auto;
                    left: -1px;
                    padding: 4px;
                    text-align: left;
                    width: 320px;
                    background-color: #262627;
                    color: white;
                    border-radius: 8px;
                    cursor: pointer;
                    z-index: $video-call-z-index;

                    .device {
                        font-size: 14px;
                        padding: 4px;
                        line-height: 24px;
                        border-radius: 4px;

                        i {
                            margin: 0 4px;
                        }
                    }
                    .device:hover, .selected {
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
                .audio-devices {
                    top: 40px;
                }
                .video-devices {
                    top: 96px;
                }
            }

            .join-call-btn {
                cursor: pointer;
                text-align: center;
                background-color: #484D51;
                padding: 8px;
                margin: auto;
                border: none;

                &.disabled {
                    background-color: #999;
                }
            }

            .off {
                color: red;
            }
        }
    }

    .name {
        position: absolute;
        max-width: calc(100% - 8px);
        left: 4px;
        bottom: 4px;
        border-radius: 4px;
        font-size: 12px;
        padding: 2px 4px;
        background-color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: white;
        z-index: $video-call-z-index + 2;

        .icon-mic-off {
            float: left;
            margin-top: 2px;
            margin-right: 2px;
        }
    }

    .video-call > .join-call-btn {
        display: none;
    }

    .action-tooltip {
        visibility: hidden;
        transition: 0s visibility;
        background-color: #18191A;
        text-align: center;
        border-radius: 4px;
        padding: 2px 8px;
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
        color: white;
        position: absolute;
        top: -12px;
        right: 8px;
    }
}

.screen-share-active {
    .video-wrapper {
        .participants {
            publisher, subscriber {
                .initials-wrapper {
                    .initials {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

#pip-wrapper.detached {
    margin: 0;
    padding: 0;
    height: 0;
}

#pip-wrapper.detached video-call {
    $wrapper-padding: 16px;
    $total-width: 320px;
    $total-height: 180px;

    top: auto;
    margin: 0;
    right: 32px;
    bottom: 32px;

    .video-call-controls,
    .full-screen-btn {
        display: none;
    }

    .loading-placeholder {
        height: $total-height;
        padding: calc( (#{$total-height} - 14px) / 2) 80px;
    }

    .no-permissions, .error {
        height: $total-height;
        padding: calc( (#{$total-height} - 90px) / 2) 0;
    }

    .video-wrapper {
        background: black;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        .arrow {
            display: none;
        }

        .participants {
            padding: 0;
            height: 100%;
            transform: none;

            .name {
                display: none;
            }

            .initials {
                font-size: 12px;
            }

            subscriber, publisher {
                .OT_publisher, .OT_subscriber, .OT_video-element {
                    border-radius: 2px;
                }

                moderator-options {
                    display: none;
                }
            }

            .OT_publisher, .OT_subscriber, .OT_video-element {
                min-height: 0;
                min-width: 0;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    #pip-wrapper {
        &.video-call {
            max-width: none;
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}
