form.vibram {

    .form-group {
        @include clearfix;
        margin-bottom: 25px;

        &.with-button {
            position: relative;

            button {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        >i:first-child {
            position: absolute;
            margin-top: 14px;
        }

        i+.form-control {
            margin-left: 26px;
            width: calc(100% - 26px);
        }
    }

    .form-control {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid rgb(222, 224, 225);
        box-shadow: none;
        font-size: 16px;
        height: 42px;
        padding: 10px 0;

        &.ng-invalid {
            border-bottom-color: red;
        }

        &[disabled],
        &[readonly] {
            background-color: #fff;
        }
    }

    textarea.form-control {
        height: auto;
    }

    select::-ms-expand {
        display: none;
    }
}

form.panel-form {
    border-radius: 4px;

    .form-container {
        padding: 8px 12px;
    }

    .form-group {
        position: relative;
    }

    .form-control {
        height: 40px;
        border-radius: 8px;
        width: 100%;
        padding: 10px 12px;
        box-sizing: border-box;
        box-shadow: none;
        border-color: var(--background-color-2);

        &:focus {
            outline: none;
            border-color: var(--contrast-color);
            box-shadow: 0 0 0 1px var(--contrast-color);
        }
    }

    textarea.form-control {
        height: 60px;
        overflow: auto;
        /* Ensure scrollbars appear only when necessary */
        resize: vertical;
    }

    textarea::-webkit-scrollbar.form-control {
        display: none;
    }

    label {
        font-size: 12px;
        text-transform: capitalize;
    }

    .error {
        color: red;
        font-size: 12px;
        display: flex;
        align-items: center;

        i {
            margin-right: 4px;
        }
    }

    .invalid {
        border-color: red;
    }

    %popover {
        position: absolute;
        background-color: var(--background-color-1);
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
        left: 0;
        z-index: 1;
        margin-top: 8px;
        border: 1px solid var(--background-color-2);
        border-radius: 8px;
    }

    .date-picker {
        @extend %popover;
        position: absolute;
        display: none;

        &.in {
            display: block;
            background-color: var(--background-color-1);
        }
    }

    .date-picker-wrapper,
    .duration-picker-wrapper {
        i {
            position: absolute;
            right: 8px;
            top: 38px;
            font-size: 16px;
            color: var(--contrast-color);
        }
    }

    .duration-picker-wrapper {

        // hide select arrow
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }

    .form-actions {
        padding: 12px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid var(--background-color-2);

        .btn+.btn {
            margin-left: 24px;
        }

        .btn-primary,
        .btn-danger {
            border: none;
            font-size: 14px;
            font-weight: 700;
        }
    }
}
