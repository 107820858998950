empty-state {
    display: block;

    .empty-state {
        background-color: var(--background-color-3);
        border: 1px solid var(--background-color-2);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 24px;

        font-size: 14px;
        font-weight: 700;
        line-height: 19px;


        .icon {
            max-height: 56px;
            margin-bottom: 16px;
        }
    }

    .subtitle {
        color: var(--foreground-color-2);
        font-weight: 400;
    }
}

@media screen and (max-width: $screen-xs-max) {
    empty-state {
        margin-top: $base-margin;
    }
}
