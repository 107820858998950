sheet {
    .modal-body {
        > div[ng-include] {
            &, > *, .nav-wrapper, .nav-column {
                height: 100%;
            }
        }

        > div[ng-include] {
            .nav-wrapper {
                max-width: none;
                width: 100%;
            }
        }

        a[ng-href] {
            margin: 0 5px;
        }
    }

    .modal.modal-sheet.menu .modal-footer {
        max-height: 320px;
        overflow: auto;
    }

    .modal-dialog {
        .modal-footer {
            .btn-group > button {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.modal-open {
    overflow: hidden;
}
