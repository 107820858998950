.nav-spotman {
    background: transparent !important;
    color: #000;
    animation-duration: 0.3s;
    border: none !important;
    border-radius: 0 !important;

    &.active {
        // needs to be above backdrop of navigation and nav-headers
        z-index: 2;
    }

    .nav-list {
        background: inherit;

        $nsize: 16px;

        .notification-badge {
            background-color: #f00;
            width: $nsize;
            height: $nsize;
            border-radius: 50%;
            position: absolute;
            margin-left: 10px;
            animation-duration: 0.5s;
            animation-timing-function: $ease-out-quad;
            z-index: 1;
            text-align: center;
            line-height: $nsize;
            font-size: 12px;

            $pads: 4px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: $nsize - $pads;
                height: $nsize - $pads;
                border-radius: 100%;
                top: 50%;
                left: 50%;
                margin-left: math.div($nsize - $pads, -2);
                margin-top: math.div($nsize - $pads, -2);
                z-index: -1;
            }
        }

        &-item {
            border: none;
            background: inherit;
            position: relative;
            padding: 5px 10px;

            &.ng-click-active {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        &-item:hover {
            background: rgba(0, 0, 0, 0.05);
        }

        &-item-icon {
            width: 20px;
            font-size: 15px;
            text-align: center;
            line-height: inherit;

            &.asset-icon {
                background-position: 50% 50%;
                background-size: contain;
                background-repeat: no-repeat;
                transform: scale(1.5);
            }
        }

        .nav-list-item-title {
            color: inherit;
        }

        .media-body {
            vertical-align: middle;
        }
    }

    .row-profile {
        color: inherit;
    }

    .icon {
        font-size: 32px;
    }

    &.search-focused {
        .nav-list-item:not(.row-universal-search):not(.row-profile):not(.row-search-result),
        .row-separator {
            display: none;
        }
    }

}

.nav-spotman-toolbar {
    color: $nav-spotman-text-color;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;

    .btn {
        padding: 4px 10px 0 10px;
        font-size: 30px;
    }
}

.nav-spotman-backdrop {
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 1s ease;
}

spotman-nav.sticky {
    position: sticky;
    top: $body-padding;
    max-height: calc(100vh - #{$body-padding + 20});
    overflow: auto;
}

html.explorer {
    spotman-nav.sticky {
        top: 0;
    }
}
