.block-meeting {

    margin: 8px 0;
    padding: 16px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid var(--background-color-2);

    header {
        @include clearfix;

        $att-size: 22px;
        span.attendance {
            float: right;
            height: $att-size;
            width: $att-size;
            color: #fff;
            text-align: center;
            border-radius: math.div($att-size, 2);
            background-color: rgb(155, 155, 155);
            cursor: pointer;

            &.yes {
                background-color: rgb(76, 217, 100);
                .icon-check {
                    display: block;
                }
            }

            &.no {
                background-color: rgb(255, 59, 48);
                .icon-cross {
                    display: block;
                }
            }

            &.maybe, &.pending {
                .question {
                    display: block;
                }
            }

            i {
                display: none;
                line-height: $att-size;
                font-style: normal;

                &.question:after {
                    content: '?';
                }
            }
        }

        h3 {
            font-size: 14px;
            margin: 0 0 8px;
        }
    }

    section {
        position: relative;
        color: rgb(155, 155, 155);
        line-height: 1.6;

        i {
            vertical-align: middle;
            margin-right: 8px;
            display: inline-block;
            min-width: 15px;
            text-align: center;
        }

        $pax-height: 36px;
        .pax-list {
            position: absolute;
            right: 0;
            bottom: 0;
            height: $pax-height;

            .pax {
                display: inline-block;

                + .pax {
                    margin-left: $pax-height * -0.5;
                }
            }
        }
    }
}
