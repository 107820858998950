video-call {

    participants-list {
        width: 100%;
        position: absolute;
        height: calc(100% - #{$video-controls-height});
        top: 0;
        left: 0;
        z-index: $video-call-z-index + 1;

        .action-list {
            position: absolute;
            z-index: 2;
            margin-top: 4px;
            background-color: #484D51;
            border-radius: 4px;
            color: white;

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                button {
                    border: none;
                    background-color: transparent;
                    border-radius: 4px;

                    &:hover {
                        background-color: #3F4041;
                    }
                }

                .option {
                    padding: 4px;
                    width: 100%;
                    text-align: left;
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 8px;
                        margin-left: 8px;
                    }

                    span {
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .participants-list {
        padding: 4px;
        position: absolute;
        width: 264px;
        height: fit-content;
        left: calc(50% - 264px/2);
        top: 24px;
        bottom: 84px;
        background: #252627;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        color: #B1B3B8;
        z-index: 1;

        .action-btn {
            font-size: 16px;
            border-radius: 2px;
            line-height: 14px;
            cursor: pointer;
            padding: 4px;
            z-index: 1;
        }

        .action-btn:hover, .profile:hover  {
            background-color: rgba(255, 255, 255, 0.2);
        }

        .close-btn {
            float: right;
            padding: 2px;
        }

        .list-header {
            overflow: hidden;
            padding: 4px;
            box-shadow: inset 0px -1px 0px #484D51;

            .label {
                float: left;
            }

            .close-btn {
                float: right;
                font-size: 16px;
                padding: 2px;
                border-radius: 2px;
                line-height: 14px;
                cursor: pointer;
            }
            .close-btn:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        .list-body {
            height: fit-content;
            max-height: 345px;
            min-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 8px;

            &.no-scroll {
                overflow: hidden;
            }

            .profile {
                padding: 8px 8px 10px;
                cursor: pointer;
                border-radius: 4px;
                line-height: 24px;
                height: 40px;
                position: relative;

                .participant-name {
                    max-width: 160px;
                    color: white;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .picture {
                    margin-right: 8px;
                    height: 24px;
                    width: 24px;
                    background-color: #B2B3B8;
                    border-radius: 50%;
                    line-height: 0;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .initials {
                        color: white;
                        position: absolute;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 24px;
                        text-transform: uppercase;
                    }

                    img {
                        display: none;
                        position: absolute;
                    }
                }

                .user-info {
                    float: left;
                    display: flex;
                    width: 150px;
                }

                .actions {
                    display: flex;
                    float: right;
                    z-index: 1;
                }

                .action-btn:hover {
                    .action-tooltip {
                        visibility: visible;
                        transition-delay: 0.2s;
                    }
                }

                .user-info:hover {
                    .open-profile-tooltip {
                        visibility: visible;
                        transition-delay: 0.2s;
                    }
                }

                .send-message-btn:hover {
                    &:not(.disabled) {
                        .send-message-tooltip {
                            display: block;
                        }
                        .already-on-chat-tooltip{
                            display: none;
                        }
                    }
                    &.disabled {
                        .send-message-tooltip {
                            display: none;
                        }
                        .already-on-chat-tooltip{
                            display: block;
                        }
                    }
                }

                .add-contact-btn:hover {
                    &:not(.disabled) {
                        .add-contact-tooltip {
                            display: block;
                        }
                        .request-sent-tooltip{
                            display: none;
                        }
                    }
                    &.disabled {
                        .add-contact-tooltip {
                            display: none;
                        }
                        .request-sent-tooltip{
                            display: block;
                        }
                    }
                }

                .disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

            }
        }

        .list-footer {
            display: flex;

            button {
                height: 32px;
                margin: 2px;
                border: none;
                border-radius: 4px;
                color: white;
                flex: 1 1 0px;
            }

            .mute-all {
                background-color: #FF3E33;
            }

            .unmute-all {
                background-color: #3F4041;
            }
        }
    }
}
