filtering {
    display: block;
    min-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    // has to be explicity specified for positioning to work correctly
    width: 255px;

    .form-horizontal .form-group {
        margin: 0;
    }

    h5 {
        margin: 15px 0 10px 0;
        padding-top: 15px;
        border-top: 1px solid #ddd;
    }

    .popover-footer-placeholder {
        height: 2.5em;
    }

    .popover-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 5px 10px;
        width: 100%;
        background: #f7f7f7;
        border-top: 1px solid #ebebeb;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        text-align: right;
    }

    .tags {
        $tags-gutter: 4px;

        margin-left: $tags-gutter * -1;
        margin-right: $tags-gutter * -1;

        &.collapsed {
            .checkboxes {
                max-height: 80px;
                overflow: hidden;
            }
        }

        .more {
            margin-left: $tags-gutter;
            cursor: pointer;
        }

        .checkbox {
            display: inline-block;
            margin: 0 $tags-gutter 8px;
            padding: 0;
            font-size: 0;

            label {
                transition: background-color .2s ease-in-out;
                background-color: #fff;
                position: relative;
                overflow: hidden;
                white-space: nowrap;
                border: 1px solid;
                border-radius: 20px;
                height: 32px;
                line-height: 32px;
                padding: 4px 16px;

                i {
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 8px;
                    overflow: hidden;
                    transition:
                        width .2s ease-in-out,
                        margin-left .2s ease-in-out,
                        margin-right .2s ease-in-out;
                }

                i.checker {
                    height: 8px;
                    width: 8px;
                    border-radius: 100%;
                    background-color: #333;
                    vertical-align: top;
                    margin-top: 8px;
                    margin-right: 8px;
                }

                i.closer {
                    height: 12px;
                    width: 0;
                    margin-left: 0;
                    color: #333;
                    position: relative;


                    &::after {
                        content: '\d7';
                        position: absolute;
                        height: 12px;
                        width: 12px;
                        display: block;
                        top: 0;
                        left: 0;
                        line-height: 6px;
                        font-size: 18px;
                    }
                }

                input {
                    visibility: hidden;
                    position: absolute;
                    margin: 0;

                    height: 1px;
                    width: 1px;
                    top: -1px;
                    left: -1px;

                    &:checked {
                        ~ i.checker {
                            width: 0;
                            margin-right: 0;
                        }

                        ~ i.closer {
                            margin-left: 6px;
                            width: 10px;
                        }
                    }
                }

                .text {
                    vertical-align: top;
                    line-height: 22px;
                    font-size: 14px;
                    color: #333;

                    max-width: 174px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                }
            }
        }
    }
}

.selected-filters {
    position: fixed;
    bottom: 6px;
    left: 0;
    right: 0;
    text-align: center;

    .filters {
        border-radius: 40px;
        display: inline-block;
        padding: 8px 24px;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        max-width: 80vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.select {
    position: relative;

    .select-wrapper {
        height: 30px;
        display: flex;
        align-items: stretch;
        border: 1px solid #ddd;
        margin: 0 auto 3px auto;
        padding: 5px;
        background-color: white;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        z-index: 0;

        .selector {
            position: absolute;
            right: 0;
            top: 6px;
            padding-top: 2px;
            width: 20px;
            height: 20px;
            color: #8B8C8C;
        }

        .placeholder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 240px;
            color: #8B8C8C;
        }
    }
    .select-wrapper:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .clear-filter-btn {
        position: absolute;
        right: 0;
        top: -26px;
        cursor: pointer;
        color: #7D8082;
        font-size: 12px;
    }

    .options {
        position: absolute;
        max-height: 200px;
        overflow: auto;
        margin: auto;
        padding: 4px;
        text-align: left;
        width: 285px;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #ddd;
        cursor: pointer;
        z-index: 1;
        color: #18191A;

        .option {
            font-size: 14px;
            padding: 4px;
            line-height: 24px;
            border-radius: 4px;
        }
        .option:hover{
            background-color: #f7f7f7;
        }

        .no-option, .no-option:hover {
            cursor: default;
            background-color: white;
            color: #8B8C8C;
        }
    }

    .selected-values {
        display: flex;
        flex-wrap: wrap;

        .selected-value {
            margin: 4px 4px 4px 0;
            background-color: red;
            color: white;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            border: 1px solid;
            border-radius: 20px;
            height: 32px;
            line-height: 23px;
            padding: 4px 16px;
            width: fit-content;
            cursor: pointer;
            max-width: 240px;

            i {
                display: inline-block;
                vertical-align: top;
                margin-top: 7px;
            }

            i.closer {
                color: white;
                height: 12px;
                width: 0;
                margin-left: 6px;
                margin-right: 6px;
                position: relative;

                &::after {
                    content: '\d7';
                    position: absolute;
                    height: 12px;
                    width: 12px;
                    display: block;
                    top: 0;
                    left: 0;
                    line-height: 6px;
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    filtering {
        max-height: 55vh;
    }
}
