.nav-list {
    &.without-header {
        height: 100%;
    }

    .tile.last .nav-list-item {
        border-bottom: 0;
    }

    &.nav-content {
        background-color: var(--background-color-3);
        border: 1px solid var(--background-color-2);
        border-radius: 4px;
        overflow: hidden;
    }
}

.nav-list-no-content {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
}

.nav-list-no-content-btn {
    margin-top: 20px;
}

.nav-list-item {
    overflow: hidden;
}

.aspect_fit div[lazy-background-asset-src] {
    background-size: contain;
}

.fill div[lazy-background-asset-src] {
    background-size: 100% 100%;
}

.nav-sidebar {
    .search {
        display: block;
        width: 100%;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid $boxed-content-borders-color;
    }

    filtering {
        max-height: none;
        width: auto;

        .popover-footer-placeholder {
            display: none;
        }
        .popover-footer {
            position: initial;
            margin-top: 15px;
        }
    }
}
