.block-new-post {
    padding: 15px 10px 0;

    .top-section {
        display: flex;
        align-items: center;
        justify-content: center;

        &.upload-disabled {
            margin-bottom: 15px;
        }
    }

    upload-block {
        margin-left: 50px;
    }
}
