.agenda-row {
    border-left: 5px solid #fff;
    height: 70px;
    overflow: hidden;

    .media-object {
        width: 65px;
        overflow: hidden;
        line-height: 1em;
        margin-top: 5px;

        .breakout-expander-icon {
            width: 10px;
            display: inline-block;
            text-indent: -5px;
            font-size: 135%;
            vertical-align: text-bottom;
        }

        .nav-list-item-title {
            font-weight: bold;
            font-size: 80%;
        }
        .nav-list-item-subtitle {
            font-size: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-indent: 10px;
        }
    }

    &.agenda-row-regular {
        .breakout-expander-icon {
            visibility: hidden;
        }
    }

    &.agenda-row-master {
        height: 45px;
        line-height: 45px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
        background: #f3f3f3;

        &.ng-click-active {
            background: rgba(0, 0, 0, 0.2);
        }

        &.expanded {
            background: #ddd;
        }

        .media-object {
            line-height: inherit;
            margin-top: 0;
        }
    }
}

.breakout-wrapper {
    overflow: hidden;
}

.breakout {
    animation-name: slideInDown;
    animation-duration: 0.5s;

    .agenda-row-regular {
        background: #f3f3f3;
    }
}
