$video-call-z-index: 12;

video-call-modal {
    position: absolute;
    width: 264px;
    left: calc(50% - 264px/2);
    top: 150px;
    border: 1px solid rgba(72, 77, 81, 1);
    border-radius: 8px;
    background-color: #252627;
    display: flex;
    flex-direction: column;
    z-index: $video-call-z-index + 4;

    .title {
        margin: 16px 16px 4px 16px;
        font-size: 16px;
        font-weight: 600;
        color: #E4E6EB;
    }

    .hint {
        margin: 0 16px 4px 16px;
        font-size: 14px;
        font-weight: 400;
        color: #B1B3B8;
    }

    a {
        color: #B1B3B8;
        text-decoration: underline;
    }

    .unmute, .cancel {
        height: 32px;
        border: none;
        border-radius: 4px;
        color: white;
    }

    .unmute {
        margin: 16px 16px 8px 16px;
        background-color: #FF3E33;
    }

    .cancel {
        margin: 0 16px 8px 16px;
        background-color: #3F4041;
    }
}
