.webinar-header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 16px;
    display: flex;
    color: #fff;
    background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);

    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    transform: translateY(-50%);
    opacity: 0;

    .icon {
        height: 32px;
        width: 32px;
        margin-right: 8px;

        &.no-icon {
            display: none;
        }

        img {
            object-fit: contain;
        }
    }

    .title {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
    }

    .date {
        font-size: 12px;
        line-height: 12px;
        font-weight: normal;
        margin: 0;
    }
}

.show-webinar-header .webinar-header {
    transform: translateY(0);
    opacity: 1;
}
