.nav-note {
    .icon-loader {
        display: block;
    }

    .parent-preview {
        background: $boxed-content-background-color;
        border: 1px solid $boxed-content-borders-color;
        padding: 10px 15px 5px 15px;
        font-size: 0.9em;
        cursor: pointer;
        border-radius: 4px;
        margin: 15px 15px 0;

        i {
            float: right;
            margin-top: 3px;
            margin-left: 15px;
            font-size: 24px;
        }
    }

    textarea {
        display: block;
        width: 100%;
        min-height: calc(100vh - #{$body-padding * 2} - 10px);
        padding: 15px;
        margin: 0;
    }

    .editor {
        display: block;

        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;

        .ql-editor {
            min-height: 360px;
        }
    }

    ng-quill-toolbar {
        display: none;
    }
}

nav .nav-note .nav-header-actions .btn {
    position: relative;
}

sheet .nav-note {
    ng-quill-toolbar {
        display: block;
    }

    textarea {
        height: 100%;
    }

    .editor {
        border: 0;
    }
}

@media screen and (max-width: $screen-sm-max) {
    .nav-note {
        textarea {
            font-size: 16px;
        }
    }
}
