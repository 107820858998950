@charset 'UTF-8';

@use "sass:math";

@import 'frontloader-common/scss/common';

// Main manifest file
@import "abstracts/functions";
@import "abstracts/variables";
@import "abstracts/mixins";

// Application specifics
@import 'default/base';

@import 'legacy/base';
@import 'components';


// =======================================
// Large, Mid and Small resolution devices
// =======================================

@media (min-width: $screen-sm-min) {
  @import 'sm-up/base';
}

// =======================
// Tablets and Smartphones
// =======================
@media screen and (max-width: $screen-sm-max) {
    @import 'sm-down/base';
}

// ================
// Smartphones only
// ================

@media screen and (max-width: $screen-xs-max) {
  @import 'xs-only/base';
}
