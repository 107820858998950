feed-reply-block {
    display: block;
    padding: 0 15px;
    margin: 0 -15px 5px -15px;
    border-bottom: 1px solid #f3f3f3;
    overflow: hidden;

    // @see https://spotme.atlassian.net/browse/SS-9712
    color: #333;

    // used to offset for 5px padding added to .nav-list-item-title*
    // which is being added there to make them aligned with block actions
    // as each button has a 5px padding for hover effect
    // (default value is 10px)
    .media-left {
        padding-right: 5px;
    }

    .media {
        display: flex;
    }

    .media-left, .media-body, .media-right {
        flex: 0 1 auto;
        min-width: 32px;
    }

    .media-body {
        width: auto;
        flex: 1 1 auto;
    }

    .nav-list-item-title {
        cursor: pointer;
        padding-left: 5px;
    }

    .nav-list-item-subtitle {
        margin-bottom: 0;
    }

    .nav-list-item-subsubtitle {
        font-weight: normal;
        margin-left: 5px;
    }

    .nav-list-item-body {
        padding: 5px 0 5px 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    footer-buttons {
        margin-bottom: 5px;
    }

    footer-buttons .action {
        display: inline-block;
        padding: 2px 5px;
        cursor: pointer;
        border-radius: 4px;
    }


    & feed-reply-block {
        font-size: 0.9em;

        &.first {
            padding-top: 5px;
            border-top: 1px solid #f3f3f3;
        }
    }
}
