app-nav-bar {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    $icon-size: 24px;

    .wrapper-inner {
        max-width: $main-content-width;
        margin: 0 auto;
    }

    .item {
        display: inline-block;
        text-align: center;
        padding: 5px 15px;
        margin: 5px 0px;
        cursor: pointer;
        color: inherit;
        vertical-align: middle;
        border-radius: $icon-size;
        position: relative;

        .title {
            opacity: 0.8;
            margin: 0 0 0 10px;

            &:after {
                display: block;
                content: attr(title);
                font-weight: bold;
                height: 0;
                overflow: hidden;
                visibility: hidden;
                margin-left: 34px;
            }
        }

        &:hover {
            text-decoration: none;
            color: inherit;

            .title {
                opacity: 1;
            }
        }

        &.active {
            .title {
                opacity: 1;
                font-weight: bold;
            }

            .asset-icon {
                transform: scale(1.6);
            }
        }

        .notification-badge {
            display: inline-block;
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            margin-left: 10px;
            margin-top: -5px;
            font-size: 0.8em;
            // height 22 - border 4px
            line-height: 18px;
            border: 2px solid #fff;
            text-align: center;
            z-index: 1;
            left: 0;
        }
    }

    .nav-list-item-icon {
        display: inline-block;
        font-size: $icon-size;
        background-size: contain;
        vertical-align: middle;

        &.asset-icon {
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            transform: scale(1.5);
            transition: scale .3s ease;
        }
    }

    app-search {
        float: right;
    }
}

@media screen and (max-width: $screen-xs-max) {
    app-nav-bar {
        width: 100%;

        .wrapper {
            width: 100%;
            padding: 0 10px;
        }

        .wrapper-inner {
            display: flex;
        }

        .item {
            flex-grow: 1;

            .title {
                display: none;
            }
        }
    }
}

@media screen and (min-width: $screen-xs-max) {
    #app-nav-bar-item-spotman_row {
        display: none;
    }
}
