$objects-max-width: 800px;
$medium-margins: 3px;
$zoom-header-icons-size: 32px;
$zoom-arrows-size: 36px;
$gallery-footer-icon-size: 26px;

image-medium {
    display: block;
}

.no-obj-fit { display: none; }

.medium {
    cursor: pointer;
    display: block;

    .media-type > * {
        padding: $medium-margins;
    }
}

.zoom {
    text-align: center;
}

.zoom-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;

    &.animated {
        transition: top .2s ease-in-out,
                left .2s ease-in-out,
                width .2s ease-in-out,
                height .2s ease-in-out;
        // Forces 3D accel.
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000;
    }

    &:not(.animating) {
        .media-container > img,
        .media-container > .vid {
            min-height: 0;
            height: auto;
            width: auto;
            max-height: 100%;
            max-width: 100%;
            transform: translate3d(-50%, -50%, 0);
            position: absolute;
            top: 50%;
            left: 50%;
        }

        .media-container > .vid {
            min-width: 320px;
            min-height: 150px;
        }
    }

    &.in {
        display: block;
    }

    &.completed {
        header, .arrow, .slick-current .caption {
            opacity: 1;
        }
    }

    &.closing {
        header, .arrow, .slick-current .caption {
            display: none;
        }
    }

    &.fullscreen {
        header .fullscreen {
            &:before {
                top: auto;
                left: auto;
                bottom: 0;
                right: 0;
            }

            i:before {
                top: auto;
                right: auto;
                bottom: 0;
                left: 0;
            }

            &:after {
                bottom: auto;
                right: auto;
                top: 0;
                left: 0;
            }

            i:after {
                bottom: auto;
                left: auto;
                top: 0;
                right: 0;
            }
        }
    }

    header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 5px 5px 10px 15px;
        color: #fff;
        opacity: 0;
        transition: opacity .5s ease-in-out;
        z-index: 1;
        background-color: rgba(0, 0, 0, .5);
        line-height: $zoom-header-icons-size;

        span.action {
            margin-right: 10px;
            cursor: pointer;
        }

        .fullscreen {
            display: inline-block;
            float: right;
            position: relative;
            height: 18px;
            width: 22px;
            margin-top: 7px;


            &:after, &:before, i:after, i:before {
                position: absolute;
                display: block;
                content: '';
                width: 6px;
                height: 6px;
            }

            &:before {
                top: 0;
                left: 0;
                border-left: 2px solid white;
                border-top: 2px solid white;
            }

            i:before {
                top: 0;
                right: 0;
                border-right: 2px solid white;
                border-top: 2px solid white;
            }

            &:after {
                bottom: 0;
                right: 0;
                border-right: 2px solid white;
                border-bottom: 2px solid white;
            }

            i:after {
                bottom: 0;
                left: 0;
                border-left: 2px solid white;
                border-bottom: 2px solid white;
            }

        }

        .close {
            color: #fff;
            opacity: 1;
            line-height: 1;
            font-weight: normal;
            font-size: 26px;
            display: inline-block;
            height: $zoom-header-icons-size;
            text-align: center;
            width: $zoom-header-icons-size;
        }
    }

    .inner {
        display: flex;
        height: 100%;
        justify-content: center;

        .content, slick, .slick-list, .item, .medium, .media-wrapper, .media-container {
            height: 100%;
            width: 100%;
        }

        .item {
            position: relative;
        }

        .slick-track {
            height: 100%;
        }
    }

    slick {
        opacity: 0;

        &:last-child:first-child {
            opacity: 1;
        }
    }

    .arrow {
        opacity: 0;
        position: absolute;
        top: 50%;
        margin-top: math.div($zoom-arrows-size, -2);
        height: $zoom-arrows-size;
        width: $zoom-arrows-size;
        background-color: rgba(0, 0, 0, .4);
        text-align: center;
        line-height: 1;
        color: #fff;
        font-size: 20px;
        padding: 0;
        border: 0;
        transition: background-color .2s ease-in-out, opacity .5s ease-in-out;
        z-index: 2;

        &[disabled] {
            opacity: 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .8);
        }

        &:focus {
            outline: none;
        }

        &.next {
            right: 10px;
            &:after {
                content: '→';
            }
        }

        &.prev {
            left: 10px;

            &:after {
                content: '←';
            }
        }
    }

    .no-obj-fit { display: none; }

    .media-container {
        display: flex;
        align-items: center;

        ~ .media-container {
            display: none;
        }
    }

    .caption {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        color: #fff;
        left: 0;
        padding: 15px;
        position: absolute;
        right: 0;
        opacity: 0;
        display: block;
        max-height: 60vh;
        overflow: auto;
        transition: opacity .5s ease-in-out;

        &:empty {
            display: none;
        }

        span {
            &.author {
                margin-right: 10px;
            }

            &.time {
                opacity: .7;
                margin-top: 10px;
            }

            &.description {
                margin-top: 10px;
                display: block;
            }
        }
    }

    .actions {
        text-align: right;
    }

    .action {
        color: #fff;
        display: inline-block;
        margin: 0 5px;

        img {
            height: $gallery-footer-icon-size;
            width: $gallery-footer-icon-size;
            display: inline-block;
        }
    }

    .hls {
        max-width: 100%;
    }
}

.zoom-backdrop {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;

    &.in {
        z-index: 10;
        opacity: 1;
    }

    &.animated {
        transition: opacity .2s linear, z-index .2s linear;
    }
}


@media (hover: none) {
    .zoom-container {
        .arrows {
            display: none;
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .zoom-container.completed {
        &.detailed {
            header, .slick-current .caption {
                opacity: 1;
            }
        }

        header, .slick-current .caption {
            opacity: 0;
        }
    }
}
